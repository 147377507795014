import _find from 'lodash/find'

import { types as listingsModeTypes } from '~/lib/config/listing-search-mode-types'
import { formatCurrency } from './format-currency'

export function formatShortPriceString(value, suffix = '', shortenThousands = false) {
  const millions = value / 1e6
  if (millions >= 1) {
    return `$${millions % 1 === 0 ? millions : millions.toFixed(1)}M${suffix}`
  }
  if (shortenThousands && value >= 100000) {
    return `${formatCurrency(value / 1000, 0)}k${suffix}`
  }
  return `${formatCurrency(value, 0)}${suffix}`
}

export function formatPriceRange(priceRange, mode) {
  const f = price => formatShortPriceString(price) + (mode === listingsModeTypes.Rent ? 'pw' : '')
  if (priceRange.minimum) {
    if (priceRange.maximum) {
      return `${f(priceRange.minimum)} to ${f(priceRange.maximum)}`
    }
    return `${f(priceRange.minimum)} plus`
  }
  if (priceRange.maximum) {
    return `up to ${f(priceRange.maximum)}`
  }
  return ''
}

const thisYear = new Date().getFullYear()
const lastYear = thisYear - 1
const nextYear = thisYear + 1

const isPreformatted = price => {
  if (!price) {
    return false
  }
  return (
    price[0] === '$' &&
    (price[price.length - 1].toLowerCase() === 'm' || price[price.length - 1].toLowerCase() === 'k')
  )
}

const getPricesArray = price => {
  const priceArray = []
  price.split(' ').forEach(s => {
    if (isPreformatted(s)) {
      priceArray.push(s)
    } else {
      // Strip out decimal places, replace non-numerics with zero-length string
      const val = s.replace('.00', '').replace(/[\D+|]/g, '')
      if (val.length) {
        priceArray.push(val)
      }
    }
  })
  return priceArray
}

export function formatPrice(price, suffix = '', shorten = true) {
  if (!price || !price.length || price.length < 3) {
    return null
  }

  // split our string by spaces and create an array of only numeric/pre-formatted values
  const priceNumbers = getPricesArray(price)
  if (!priceNumbers.length) {
    return null
  }

  // if it's preformatted, return it
  const priceNumber = priceNumbers[0]
  if (isPreformatted(priceNumber)) {
    return priceNumber
  }

  // Phone number or zero value
  if (priceNumber.indexOf('0') === 0) {
    return null
  }

  // Parse then some basic validation
  const priceVal = parseFloat(priceNumber)
  // Attempt to weed out dates
  if (priceVal > 31 && priceVal !== lastYear && priceVal !== thisYear && priceVal !== nextYear) {
    if (shorten) {
      return formatShortPriceString(priceVal, suffix, true)
    }
    return priceVal
  }
  return null
}

export function splitPrice(price, seperator = '-', suffix = '') {
  const range = price.split(seperator)
  if (range.length === 2) {
    const rangeA = formatPrice(range[0].trim(), suffix)
    const rangeB = formatPrice(range[1].trim(), suffix)
    return rangeA && rangeB ? `${rangeA}-${rangeB}` : rangeA || rangeB
  }
  return null
}

// Used by map pins
const ignoreStrings = ['contact agent', 'under offer', 'private sale']
export function formatShortPrice(price, isRent) {
  if (!price || !price.length || _find(ignoreStrings, price.toLowerCase())) {
    return null
  }
  const suffix = isRent ? 'pw' : ''
  // Try and split out price range
  if (price.indexOf('-') > 0) {
    return splitPrice(price, '-', suffix)
  }

  if (price.indexOf(' to ') > 0) {
    return splitPrice(price, 'to', suffix)
  }

  return formatPrice(price, suffix)
}
