import React, { PropsWithChildren, useCallback, useEffect, useMemo, type FC } from 'react'
import { useNavigate, useLocation } from '@remix-run/react'

import { captureException } from '@sentry/remix'
import type { User } from '~/graphql/generated/graphql'
import { asPathWithoutQuerystring } from '~/modules/location/utils/get-location-uri-parts'
import { useAsync } from './utils/use-async'
import {
  getUser,
  postSignUp,
  postSignIn,
  postSignOut,
  postForgotPassword,
  refreshAccessTokenIfNeeded,
  postSocialSignin,
} from './utils/auth'

interface AuthContextProps {
  user: User
  signUp: (any) => Promise<any>
  signOut: (any) => Promise<any>
  signIn: (any) => Promise<any>
  forgotPassword: (any) => Promise<any>
}

const AuthContext = React.createContext<Partial<AuthContextProps>>({})
AuthContext.displayName = 'AuthContext'
let authRefreshTimer
const authTimerTimeout = 300000 // 5 minutes

interface Props {
  auth?: any // TODO
}

const AuthProvider = ({ auth = null, ...props }: PropsWithChildren<Props>) => {
  const { data, setData } = useAsync({ data: auth })

  const navigate = useNavigate()
  const location = useLocation()

  const checkUser = useCallback(() => {
    return refreshAccessTokenIfNeeded()
      .then(response => {
        if (response !== false) {
          getUser().then(user => {
            setData(user)
            authRefreshTimer = user ? setTimeout(checkUser, authTimerTimeout) : null
          })
        }
      })
      .catch(e => {
        captureException(e)
      })
  }, [setData])

  const startTimer = useCallback(() => {
    if (!authRefreshTimer) {
      authRefreshTimer = setTimeout(checkUser, authTimerTimeout)
    }
  }, [checkUser])

  const withUser = useCallback(
    action =>
      action.then(user => {
        setData(user)
        startTimer()
        return user
      }),
    [setData, startTimer],
  )

  const signUp = useCallback(form => withUser(postSignUp(form).then(user => user)), [withUser])
  const signIn = useCallback(form => withUser(postSignIn(form).then(user => user)), [withUser])
  const signOut = useCallback(
    () =>
      postSignOut().then(() => {
        setData(null)
        authRefreshTimer = null
      }),
    [setData],
  )
  const forgotPassword = useCallback(form => postForgotPassword(form), [])

  const clearQuerystring = () => {
    const searchParams = new URLSearchParams(location.search)
    const excludeKeys = ['providerName', 'authCode', 'fullName', 'email', 'authError']
    for (const key of excludeKeys) {
      searchParams.delete(key)
    }
    navigate(
      `${asPathWithoutQuerystring(location.pathname)}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`,
      { replace: true },
    )
  }

  // Checks for auth keys in the querystring and performs login
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const providerName = searchParams.get('providerName')
    const authCode = searchParams.get('authCode')
    if (providerName && authCode) {
      try {
        withUser(
          postSocialSignin(Object.fromEntries(searchParams))
            .then(user => {
              clearQuerystring()
              if (!user) {
                console.error('Unable to retrieve user info')
                return null
              }
              return user
            })
            .catch(e => {
              captureException(e)
              clearQuerystring()
            }),
        )
      } catch (e) {
        captureException(e)
        clearQuerystring()
      }
    }
  }, [location.search, setData, withUser])

  useEffect(() => {
    // Check cookies on window focus to give user immediate feedback if returning after a long time away
    window.addEventListener('focus', checkUser)

    return () => {
      window.removeEventListener('focus', checkUser)
    }
  }, [checkUser])

  useEffect(() => {
    getUser().then(user => {
      setData(user)
      startTimer()
    })
    return () => {
      authRefreshTimer = null
    }
  }, [setData, checkUser, startTimer])

  const value = useMemo(
    () => ({ user: data, signIn, signOut, signUp, forgotPassword }),
    [data, signIn, signOut, signUp, forgotPassword],
  )
  return <AuthContext.Provider value={value} {...props} />
}

const useAuth = () => React.useContext(AuthContext)

export { AuthContext, AuthProvider, useAuth }
