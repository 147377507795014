/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import { ApolloClient, InMemoryCache, type NormalizedCacheObject } from '@apollo/client'
import merge from 'deepmerge'
import { canUseDOM } from '~/lib/utils/can-use-dom'
import isEqual from 'lodash/isEqual'
import { useMemo } from 'react'
import { get } from '~/lib/config/app-config'
import possibleTypes from './possibleTypes.json'
import { getAppVersion } from '../lib/utils/helpers'
import { createClientLinks } from './links'


const isSSR = !canUseDOM




interface CreateApolloClientProps {
  fetchOptions?: any
}

export const createApolloClient = (props: CreateApolloClientProps = {}) => {
  const { fetchOptions  } = props
  const config = get()
  const uri = config.bffUrl

  const headers = {
    'x-no-placeholder-images': 'true',
  }

  const connectionOptions = {
    uri,
    fetch,
    headers,
    fetchOptions,
  }

  return new ApolloClient<NormalizedCacheObject>({
    name: isSSR ? 'ssr-web' : 'web',
    version: getAppVersion(),
    ssrMode: isSSR,
    cache: new InMemoryCache({ typePolicies: { Slugs: { keyFields: [] } }, possibleTypes }),
    link: createClientLinks(connectionOptions),
  })
}

interface InitializeApolloProps {
  initialState?: any
  fetchOptions?: any
}

let cachedApolloClient

export const initializeApollo = (props: InitializeApolloProps = {}) => {
  const { initialState = null, fetchOptions  } = props
  const apolloClient = cachedApolloClient
    ? cachedApolloClient
    : createApolloClient({ fetchOptions })

  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return apolloClient
  }

  // Create the Apollo Client once in the client
  if (!cachedApolloClient) {
    cachedApolloClient = apolloClient
  }

  return cachedApolloClient
}

export const useApollo = (initialState): ApolloClient<NormalizedCacheObject> => {
  const store = useMemo(() => initializeApollo({ initialState }), [initialState])
  return store
}
