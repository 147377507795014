type LocationUriParts = {
  locationType: string;
  byLocationType?: string;
};

export const asPathWithoutQuerystring = (asPath: string) =>
  asPath.includes('?') ? asPath.substring(0, asPath.indexOf('?')) : asPath;

export const getLocationUriParts = (locType: string, asPath: string): LocationUriParts => {
  const values = locType?.split('-by-');

  const pathWithoutQuerystring = asPathWithoutQuerystring(asPath);

  // find-by
  if (values && values.length > 0) {
    const locationType = values[0];
    const byLocationType = values[1];
    return { locationType, byLocationType };
  }

  // where-to-live
  if (pathWithoutQuerystring === '/where-to-live') {
    // Not sure if we need this, as it's not used as a variable in the GQL query.
    return { locationType: 'places'}
  }

  // find
  // Leaving this as a catch-all for now, as it's not cleat if it's still used outside GQL.
  const locationType = pathWithoutQuerystring.split('-')[1];

  return { locationType };
};

// where-to-live route does not use GQL data
export const LocationSearchTypes = {
  popular: { value: 'popular', label: 'Popular suburbs', href: '/where-to-live' },
  regions: { value: 'regions', label: 'Regions', href: '/find-region' },
  suburbs: { value: 'suburbs', label: 'Suburbs', href: '/find-suburb' },
  streets: { value: 'streets', label: 'Streets', href: '/find-street' },
};

export const LocationSearchTypesArray: any[] = Object.values(LocationSearchTypes);

export const isPlacesRoute = (path: string): boolean => path === LocationSearchTypes.popular.href;

export const getActiveRoute = (path: string) => {
  if (path.includes(LocationSearchTypes.regions.href)) return LocationSearchTypes.regions.value;
  if (path.includes(LocationSearchTypes.suburbs.href)) return LocationSearchTypes.suburbs.value;
  if (path.includes(LocationSearchTypes.streets.href)) return LocationSearchTypes.streets.value;
  return LocationSearchTypes.popular.value;
};

export default { getLocationUriParts, isPlacesRoute };
