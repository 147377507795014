import {
  type EventFilter,
  ListingSearchContextType,
  type MinAndMaxFilter,
  type OnlyAndMinFilter,
  type SearchLocation,
  type SearchParams,
} from '~/graphql/generated/graphql'

export const pageSize = 25

export const mergeOnlyAndMinFilter = (filter: OnlyAndMinFilter, asString = false) => {
  if (asString) {
    return [].concat(
      ...(filter?.only?.map(v => v.toString()) || []),
      filter?.min ? filter.min.toString() : [],
    )
  }
  return [].concat(
    ...(filter?.only?.map(v => Number(v)) || []),
    filter?.min ? Number(filter.min) : [],
  )
}

export const getCurrentPage = (params: SearchParams) => (params?.paging?.skip || 0) / pageSize + 1

export const getActiveDates = (event: EventFilter) => {
  const dates = [...(event?.dates || [])]
  if (event?.forthcoming) {
    // zero-length string is the value used for "all" dates
    dates.push('')
  }
  return dates
}

export const getLocationNameFromSearchLocation = (location: SearchLocation) => {
  if (!location?.name) {
    return ''
  }
  const { name, stateCode, postcode } = location
  return `${name}${stateCode ? `, ${stateCode}` : ''} ${postcode || ''}`.trim()
}

export const arrayToEventFilter = (array: string[] = []) => {
  const forthcoming = array.includes('')
  const dates = array.filter(date => date !== '')
  return !forthcoming && dates.length === 0 ? null : ({ forthcoming, dates } as EventFilter)
}

export const normaliseSearchParams = (
  searchParams: SearchParams,
  partialParams: any,
  defaultParams: SearchParams,
) => {
  // Ensure we get the proper page size if paging is set to null
  const paging = partialParams?.paging || defaultParams?.paging
  return { ...defaultParams, ...searchParams, ...partialParams, paging }
}

export const getSearchParamsQuery = (searchParams: SearchParams) => {
  if (!searchParams) {
    return null
  }

  // Reduce location to id.
  const locationSearchContext = {
    ...searchParams?.locationSearchContext,
    searchLocations:
      searchParams?.locationSearchContext?.searchLocations?.map(({ id }) => ({ id })) || [],
  }

  const params = { ...searchParams, ...{ locationSearchContext } } as any

  // Need to remove location object if this isn't a location search
  if (searchParams.context !== ListingSearchContextType.Location) {
    delete params.locationSearchContext.searchLocations
  } else {
    delete params.locationSearchContext.wellKnownText
  }

  return `searchParamsJSON=${JSON.stringify(params)}`
}

// SearchParams Comparison functions

export const compareArrays = (arrayA: any[], arrayB: any[], isExact = false): boolean => {
  // Match nulls
  if (!arrayA && !arrayB) {
    return true
  }
  // mistmatched nulls
  if ((!arrayA && !!arrayB) || (!!arrayA && !arrayB)) {
    return false
  }
  // match array length
  if (!arrayA?.length !== !arrayB?.length) {
    return false
  }
  // match array contents
  if (!isExact) {
    return (
      arrayA.every(value => arrayB.includes(value)) && arrayB.every(value => arrayA.includes(value))
    )
  }
  return (
    arrayA.every((value, index) => value === arrayB[index]) &&
    arrayB.every((value, index) => value === arrayA[index])
  )
}

export const compareOnlyAndMinFilter = (
  filterA: OnlyAndMinFilter,
  filterB: OnlyAndMinFilter,
): boolean => {
  if (!filterA && !filterB) {
    return true
  }
  if (!filterA || !filterB) {
    return false
  }
  const { only: onlyA, min: minA } = filterA || {}
  const { only: onlyB, min: minB } = filterB || {}

  return minA === minB && compareArrays(onlyA, onlyB)
}

export const compareMinAndMaxFilter = (
  filterA: MinAndMaxFilter,
  filterB: MinAndMaxFilter,
): boolean => {
  if (!filterA && !filterB) {
    return true
  }
  if (!filterA || !filterB) {
    return false
  }
  return filterA.min === filterB.min && filterA.max === filterB.max
}
