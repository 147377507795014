import React, { useState, useEffect, type ReactNode, useContext } from 'react'
import { useLocation } from '@remix-run/react'
import { getPageInfo } from '~lib/utils/viewport'

export interface PageInfoContextProps {
  width: number
  height: number
  isDesktop: boolean
  isTablet: boolean
  isDesktopLarge: boolean
  isAboveTiny: boolean
  isAboveSmall: boolean
  isAboveMedium: boolean
  isAboveDesktop: boolean
  isAboveLarge: boolean
  isAboveLarger: boolean
  isFirstLoad: boolean
  isClient: boolean
  isIos: boolean
}

interface Props {
  children: ReactNode
}

const PageInfoContext = React.createContext<PageInfoContextProps | undefined>(undefined)

const PageInfoProvider = ({ children }: Props) => {
  const location = useLocation()

  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isClient, setIsClient] = useState(false)
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })
  const { width, height } = windowSize

  // First Load
  useEffect(() => {
    setIsFirstLoad(false)
  }, [location])

  // Window Size
  useEffect(() => {
    const handleWindowSizeChange = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    handleWindowSizeChange()
    setIsClient(true)
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const contextValue = {
    ...getPageInfo(width, height, isFirstLoad, isClient),
  }

  return <PageInfoContext.Provider value={contextValue}>{children}</PageInfoContext.Provider>
}

const usePageInfo = () => {
  const context = useContext(PageInfoContext)
  if (context === undefined) {
    throw new Error('usePageInfo must be used within a PageInfoProvider')
  }
  return context
}

export { PageInfoContext, PageInfoProvider, usePageInfo }
