export const config = {
  apiVersion: '1',
  adminUrl: 'https://admin.homely.com.au',
  apiUrl: 'https://dev-api.homely.com.au',
  apiLegacyUrl: 'https://qa.homely.com.au',
  bffUrl: 'https://bff-qa.homely.com.au/graphql',
  bffAuthBearerToken: '',
  bffZohoUrl: 'https://bff-qa.homely.com.au/zoho',
  wpEngineGqlUrl: 'https://homelyau.wpenginepowered.com/graphql',
  wpEngineRESTUrl: 'https://homelyau.wpenginepowered.com/wp-json/wp/v2',
  accessTokenCookieName: '.AuthDevAccessToken',
  thirdPartyCookieName: 'Auth-xwing-dev-web',
  googleAPIKey: 'AIzaSyCuOMvv00qaUEbA9hq6tWbdLm9wSBUmEFs',
  googleClientId: '841047142822.apps.googleusercontent.com',
  googleGeocodingAPIKey: 'AIzaSyAh5QsHUGZKh1BkifV1VwBYT6s7NCfggpg',
  googleTagManagerKey: 'gtm_auth=scJhVjFwL4rEuKc_J2LJ7g&gtm_preview=env-5',
  sentryDsn:
    'https://5109a490b2277401a861d31a56f82bc0@o4505842328928256.ingest.sentry.io/4505842332073984',
  wellCalculatorUrl: 'https://widget.wellhomeloans.com.au',
  nowUrl: 'https://dev-now.homely.com.au/signalr/hubs',
  cdnUrl: '',
  fbAppId: 1428158284072083,
  fbAppSecret: '40da5c509245594cef1e2c929ff8875c',
  appVersion: '',
  slackWebhookUrl: 'https://hooks.slack.com/services/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',
  appleClientId: 'au.com.homely.apple-auth',
  feature: {
    chat: false,
    facetLevel2: true,
    facetLevel3: false,
    facetLevel4: false,
    tracking: true,
    listingTrackingLog: false,
    agentTrackingLog: false,
    sentryTracking: false,
    gtmTrackingLog: false,
    myPlaces: false,
    advancedMapClustering: true,
    systemNotifications: true,
    performanceHub: true,
    awardWinners: true,
    thirdPartyScripts: true,
  },
  experiment: {},
};

export default config;
