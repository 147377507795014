import _filter from 'lodash/filter';
import _includes from 'lodash/includes';

export const IndoorFeatures = {
  AirConditioning: 'airConditioning',
  AlarmSystem: 'alarmSystem',
  Broadband: 'broadband',
  BuiltInRobes: 'builtInRobes',
  Dishwasher: 'dishwasher',
  DuctedCooling: 'ductedCooling',
  DuctedHeating: 'ductedHeating',
  EvaporativeCooling: 'evaporativeCooling',
  Floorboards: 'floorboards',
  Furnished: 'furnished',
  GasHeating: 'gasHeating',
  Gym: 'gym',
  InsideSpa: 'insideSpa',
  Intercom: 'intercom',
  Fireplace: 'openFirePlace',
  PayTV: 'payTV',
  PetFriendly: 'petFriendly',
  RumpusRoom: 'rumpusRoom',
  SplitAircon: 'splitSystemAirCon',
  SplitHeating: 'splitSystemHeating',
  Study: 'study',
  VacuumSystem: 'vacuumSystem',
};

export const list = [
  {
    value: 'airConditioning',
    label: 'Air Conditioning',
  },
  { value: 'alarmSystem', label: 'Alarm System' },
  { value: 'broadband', label: 'Broadband' },
  {
    value: 'builtInRobes',
    label: 'Built In Robes',
  },
  { value: 'dishwasher', label: 'Dishwasher' },
  {
    value: 'ductedCooling',
    label: 'Ducted Cooling',
  },
  {
    value: 'ductedHeating',
    label: 'Ducted Heating',
  },
  {
    value: 'evaporativeCooling',
    label: 'Evaporative Cooling',
  },
  { value: 'floorboards', label: 'Floorboards' },
  { value: 'furnished', label: 'Furnished' },
  { value: 'gasHeating', label: 'Gas Heating' },
  { value: 'gym', label: 'Gym' },
  { value: 'insideSpa', label: 'Inside Spa' },
  { value: 'intercom', label: 'Intercom' },
  {
    value: 'openFirePlace',
    label: 'Open Fireplace',
    uri: 'fireplace',
  },
  { value: 'payTV', label: 'Pay TV' },
  {
    value: 'petFriendly',
    label: 'Pet Friendly',
    uri: 'pet-friendly',
  },
  { value: 'rumpusRoom', label: 'Rumpus Room' },
  {
    value: 'splitSystemAirCon',
    label: 'Split Aircon',
  },
  {
    value: 'splitSystemHeating',
    label: 'Split Heating',
  },
  { value: 'study', label: 'Study' },
  {
    value: 'vacuumSystem',
    label: 'Vacuum System',
  },
];

export default list;

const canonical = [
  IndoorFeatures.Furnished,
  IndoorFeatures.PetFriendly,
  IndoorFeatures.Gym,
  IndoorFeatures.Fireplace,
];

export const IndoorCanonical = _filter(list, (item) => _includes(canonical, item.value));

export const IndoorNonCanonical = _filter(list, (item) => !_includes(canonical, item.value));
