import React, { useEffect, createContext, useState } from 'react'
import type { User } from '~/graphql/generated/graphql'
import { safelyAddToArray } from '~/lib/utils/array-helper'
import localStorage from '~/lib/utils/storage'
import { trackAuthEvent } from '~/lib/utils/auth'
import { trackUser } from '~/lib/utils/google-tag-manager'
import { useAuth } from './auth-context'

interface UserContextProps {
  user: User | null
  addViewedListing: (listingId: number) => {}
  addEnquiredListing: (listingId: number) => {}
  enquiredListings: []
  viewedListings: []
}

const UserContext = createContext<Partial<UserContextProps>>({})
UserContext.displayName = 'UserContext'

const useUser = () => React.useContext(UserContext)

const UserProvider = props => {
  const { user } = useAuth() || { user: null }

  const viewedListingsStorageKey = `user_viewed_listings-${user?.id || ''}`
  const enquiredListingsStorageKey = `user_enquired_listings-${user?.id || ''}`

  const getViewed = () => localStorage.getItem(viewedListingsStorageKey) || []
  const getEnquired = () => localStorage.getItem(enquiredListingsStorageKey) || []

  const [viewedListings, setViewedListings] = useState(getViewed())
  const [enquiredListings, setEnquiredListings] = useState(getEnquired())

  useEffect(() => {
    if (user) {
      trackUser(user.id)
      trackAuthEvent(user.id)
      setViewedListings(getViewed())
      setEnquiredListings(getEnquired())
    }
    if (!user) {
      setViewedListings([])
      setEnquiredListings([])
    }
  }, [user])

  // Viewed listings
  const addViewedListing = (listingId: number) => {
    const updatedArray = safelyAddToArray(getViewed(), listingId)
    localStorage.setItem(viewedListingsStorageKey, updatedArray)
    setViewedListings(updatedArray)
  }

  // Enquired listings
  const addEnquiredListing = (listingId: number) => {
    const updatedArray = safelyAddToArray(getEnquired(), listingId)
    localStorage.setItem(enquiredListingsStorageKey, updatedArray)
    setEnquiredListings(updatedArray)
  }

  // Append statuses to user object if it exists
  const value = {
    user,
    addViewedListing,
    addEnquiredListing,
    viewedListings,
    enquiredListings,
  }

  return <UserContext.Provider value={value} {...props} />
}

export { UserContext, UserProvider, useUser }
