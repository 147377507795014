import uiBreakpoints from '~/lib/config/ui-breakpoints'

const defaultMobileWidth = uiBreakpoints.tiny
const defaultMobileHeight = 667

const cache: { width: number | null; height: number | null } = {
  width: null,
  height: null,
}

const getDefaultSize = () => ({
  width: defaultMobileWidth,
  height: defaultMobileHeight,
})

const cacheSize = (width?: number, height?: number) => {
  if (width) cache.width = width
  if (height) cache.height = height
  if (!cache.width) cache.width = getDefaultSize().width
  if (!cache.height) cache.height = getDefaultSize().height
  return cache
}

export const isAboveBreakpoint = (value: number, size: keyof typeof uiBreakpoints) => value > uiBreakpoints[size]
export const isAboveDesktop = (width: number) => isAboveBreakpoint(width, 'large')
export const isAboveDesktopLarge = (width: number) => isAboveBreakpoint(width, 'desktopLarge')

export const getPageInfo = (w?: number, h?: number, isFirstLoad?: boolean, isClient?: boolean) => {
  const isBrowser = typeof window !== 'undefined'
  const { width, height } = isBrowser ? cacheSize(w, h) : getDefaultSize()
  const isDesktop = isAboveDesktop(width)
  const isTablet = width >= uiBreakpoints.desktop && width <= uiBreakpoints.large

  return {
    width,
    height,
    isTablet,
    isDesktop,
    isDesktopLarge: isAboveDesktopLarge(width),
    isAboveTiny: isAboveBreakpoint(width, 'tiny'),
    isAboveSmall: isAboveBreakpoint(width, 'small'),
    isAboveMedium: isAboveBreakpoint(width, 'medium'),
    isAboveDesktop: isAboveBreakpoint(width, 'desktop'),
    isAboveLarge: isDesktop,
    isAboveLarger: isAboveBreakpoint(width, 'larger'),
    isFirstLoad,
    isClient,
    isIos: false,
  }
}
