export default {
  tiny: 480, // mobile
  small: 640,
  medium: 720,
  desktop: 768,
  large: 960,
  larger: 1024,
  desktopLarge: 1240, // desktop page width 1200px with 20px padding on sides
  xlarge: 1280,
  jumbo: 1440,
};
