import localStorage from '~/lib/utils/storage'
import { trackEvent, eventTypes, GtmEvent, contentTypes } from '~/lib/utils/google-tag-manager'

const authModeKey = 'auth_mode'
const authTypeKey = 'auth_type'
const authPosKey = 'auth_pos'

export const AuthTypes = {
  SignUp: '1',
  Login: '2',
}

export const storeAuthEvent = (mode, type, pageElementPosition) => {
  localStorage.setItem(authModeKey, mode)
  localStorage.setItem(authTypeKey, type)
  localStorage.setItem(authPosKey, pageElementPosition)
}

const clear = () => {
  localStorage.removeItem(authModeKey)
  localStorage.removeItem(authTypeKey)
  localStorage.removeItem(authPosKey)
}

const getAuthEvent = () => {
  const mode = localStorage.getItem(authModeKey)
  const type = localStorage.getItem(authTypeKey)
  const pageElementPosition = localStorage.getItem(authPosKey)
  return mode && type ? { mode, type, pageElementPosition } : null
}

export const checkSocialSignUp = () => {
  const data = getAuthEvent()
  return data?.mode === AuthTypes.SignUp && data?.type !== 'email'
}

export const trackAuthEvent = userId => {
  const data = getAuthEvent()
  if (data) {
    const options: GtmEvent = {
      event: data.mode === AuthTypes.Login ? eventTypes.login : eventTypes.signUp,
      pageElementPosition: data.pageElementPosition,
      userId,
      extraData: data.type,
    }
    trackEvent(options)
    clear()
  }
}

export default {
  storeAuthEvent,
  trackAuthEvent,
  checkSocialSignUp,
}
