import api from '~/lib/config/api-endpoints'
import apiLegacy from '~/lib/config/api-endpoints-legacy'
import { get } from '~/lib/config/app-config'
import Cookies from '~/lib/utils/cookies'
import debug from '~/lib/utils/debug'
import localStorage from '~/lib/utils/storage'
import { canUseDOM } from '~/lib/utils/can-use-dom'
import { availableFeatures, isFeatureEnabled } from './features'

const storageKey = 'auth_member'

const getLocalUser = () => {
  // This will return a Local user regardless of whether they are still valid
  const user = localStorage.getItem(storageKey)
  return user ? JSON.parse(user) : null
}

const setLocalUser = user => {
  localStorage.setItem(storageKey, JSON.stringify(user))
  Cookies().update()
}

const removeLocalUser = (showTimeoutMessage = false) => {
  if (canUseDOM && getLocalUser()) {
    const hadAccessToken = !!Cookies().getAccessToken()
    const { thirdPartyCookieName, accessTokenCookieName } = get()

    localStorage.removeItem(storageKey)
    Cookies().remove(thirdPartyCookieName)
    Cookies().remove(accessTokenCookieName)
    Cookies().update()
    // If the user is being deleted and there was no access token it means their session timed out
    if (
      showTimeoutMessage &&
      !hadAccessToken &&
      isFeatureEnabled(availableFeatures.systemNotifications.key)
    ) {
      // auth context will catch this and add to notification context
      // TODO replace toast
      console.warn('Your sign-in session has ended', { icon: '⚠️' })
    }
  }
}

const getUserFromApi = async getUserUrl => {
  const ajax = (await import('~/lib/utils/ajax')).default
  return ajax
    .getJSON(getUserUrl, apiLegacy.apiHeader())
    .then(res => {
      if (res) {
        setLocalUser(res)
        return res
      }
      return null
    })
    .catch(err => {
      if (err.stack && (err?.response?.statusType !== 4 || !err.response)) {
        debug.error(err.stack)
      }
      throw err
    })
}

let isRefreshingAccessToken = false
export const refreshAccessToken = async () => {
  const refreshAuthUrl = apiLegacy.refreshAuth()
  isRefreshingAccessToken = true
  const ajax = (await import('~/lib/utils/ajax')).default
  return ajax
    .getJSON(refreshAuthUrl, apiLegacy.apiHeader())
    .then(() => {
      // The legacy API endpoint uses the existing refresh token to get a new accessToken
      Cookies().update()
      isRefreshingAccessToken = false
      if (!Cookies().getAccessToken()) {
        // No luck, user is logged out
        removeLocalUser(true)
      }
    })
    .catch(err => {
      if (err) {
        debug.error(err)
      }
      isRefreshingAccessToken = false
      throw err
    })
}

// If we still have a valid Auth cookie but the AccessToken has expired, got get a new one
export const refreshAccessTokenIfNeeded = () => {
  if (isRefreshingAccessToken) {
    return Promise.resolve(false)
  }
  // Ensure we have browser cookies, not cached cookies
  Cookies().update()
  const accessTokenCookie = Cookies().getAccessToken()
  const authCookie = Cookies().getAuthCookie()
  if (!authCookie) {
    // Auth has expired!
    return Promise.resolve(null)
  }
  if (!accessTokenCookie && authCookie) {
    return refreshAccessToken().then(() => true)
  }
  return Promise.resolve(false)
}

export const getAuthAccessToken = () => {
  const user = getLocalUser()

  let authAccessToken = user ? Cookies().getAccessToken() : null

  // Grab the token from any production API request to test production data
  // authAccessToken = {
  //   Authorization:
  //     'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IkozckNseTdPRDBtVU15Z0dMZGF2dXdnUTF3USIsImtpZCI6IkozckNseTdPRDBtVU15Z0dMZGF2dXdnUTF3USJ9.eyJpc3MiOiJodHRwczovL2F1dGguaG9tZWx5LmNvbS5hdS9jb3JlIiwiYXVkIjoiaHR0cHM6Ly9hdXRoLmhvbWVseS5jb20uYXUvY29yZS9yZXNvdXJjZXMiLCJleHAiOjE2NzkzNzE4MDUsIm5iZiI6MTY3OTM2ODIwNSwiY2xpZW50X2lkIjoiaG9tZWx5LXdlYiIsInNjb3BlIjpbIm9wZW5pZCIsInJvbGVzIiwib2ZmbGluZV9hY2Nlc3MiLCJob21lbHlfYXBpIl0sInN1YiI6IjMiLCJhdXRoX3RpbWUiOjE2NzkyODM4MzIsImlkcCI6Imlkc3J2Iiwicm9sZSI6WyJMb2NhbCIsIkFkbWluaXN0cmF0b3IiXSwiYWdlbnRzX3Blcm1pc3Npb24iOlsiQ2FuVHJpZ2dlclByZW1pZXJBZ2VudFdvcmtmbG93IiwiQ2FuQ2hhbmdlQW55QWdlbnRzU2V0dGluZyIsIkNhbk1hbmFnZUFueUFnZW50UmV2aWV3Il0sImFsZXJ0c19wZXJtaXNzaW9uIjpbIkNhbk1hbmFnZUFueSIsIm4vYSJdLCJhcHBzX3Blcm1pc3Npb24iOlsiQ2FuVG9nZ2xlRmVhdHVyZXMiLCJuL2EiXSwiY29sbGVjdGlvbnNfcGVybWlzc2lvbiI6WyJDYW5NYW5hZ2VBbnkiLCJuL2EiXSwibGlzdGluZ3NfcGVybWlzc2lvbiI6WyJDYW5WaWV3SGlkZGVuIiwiQ2FuQm9vc3QiLCJDYW5NYW5hZ2VBbnkiLCJDYW5FeGNsdXNpdmVMaXN0Il0sIm11bHRpbG9hZGVyc19wZXJtaXNzaW9uIjpbIkNhbk1hbmFnZURhdGEiLCJuL2EiXSwib2ZmaWNlc19wZXJtaXNzaW9uIjpbIkNhbkJvb3N0Iiwibi9hIl0sInRyYWNraW5nX3Blcm1pc3Npb24iOlsiQ2FuQWRkTGlzdGluZ0V2ZW50IiwiQ2FuQWRkQWdlbnRFdmVudCJdLCJ1Z2NfcGVybWlzc2lvbiI6WyJDYW5WaWV3SGlkZGVuIiwiQ2FuTWFuYWdlQW55Il0sInVzZXJzX3Blcm1pc3Npb24iOlsiQ2FuVmlld0FueU1lbWJlciIsIkNhbk1hbmFnZUFueSJdLCJhbXIiOlsiY3VzdG9tIl19.XdMc6brq0Yuwb_-7hA61N99hmK-PPSWzbM9hwZymAvHZJN71fhVLiZhv7lqT264IHOWoQQRKvapctByXB9Nf_YfMpoYF_Dr8XFzomWEICf2WpNEjjj7PQ9_b9e1VI3-p3IL5eCfC2QXL-mE7ZVmlMuFNbE7S673g7xIQ_ZLpNsyhxeWYhV9vOGU3acFxeBdtxsPyUBl67Ics2O1178sXHBlROp6IIiJ4O_cGJ34-PtiAtpvsALAElNuh5MVbVKPx5R5VMVGjPG6FJqvmmmRuSAmWgLGNxHuS16ClH8RPuoH25Bg9Vqp3FgY2BWFgTSA6uclk7bgeOoevUBBB60Vyeg',
  // };

  // Override auth token to hit another BFF (e.g. prod)
  const overrideToken = get().bffAuthBearerToken
  if (overrideToken?.length > 0) {
    authAccessToken = { Authorization: overrideToken }
  }

  return authAccessToken
}

const getValidLocalUser = (getUserUrl = '') => {
  Cookies().update()
  // Expired access token, need to hit API to get a new one
  let accessTokenCookie = Cookies().getAccessToken()
  let authCookie = Cookies().getAuthCookie()

  return refreshAccessTokenIfNeeded().then(() => {
    const user = getLocalUser()

    // If we've had to refresh via the API the cookies will have been set again by XWing
    authCookie = Cookies().getAuthCookie()
    accessTokenCookie = Cookies().getAccessToken()

    // Super legit user.
    if (authCookie && accessTokenCookie && user) {
      return user
    }

    // If no auth cookie delete Local user
    if (!authCookie && user) {
      removeLocalUser()
      return user
    }

    // If cookie is found but we don't have a local cache, get the user
    if (authCookie && accessTokenCookie && !user) {
      // Need to grab the ID off the end e.g /api/user/123
      const userId =
        getUserUrl.length > 0
          ? getUserUrl.substring(getUserUrl.lastIndexOf('/') + 1, getUserUrl.length)
          : authCookie

      const apiUrl = api.getMember(userId)
      return getUserFromApi(apiUrl)
    }

    // No cookie and no user. Nullify auth to ensure we are in sync.
    return null
  })
}

export const getUser = (getUserUrl = '') => getValidLocalUser(getUserUrl)

export const postSignIn = async data => {
  const ajax = (await import('~/lib/utils/ajax')).default
  return ajax
    .postJSON(apiLegacy.signin(), data, apiLegacy.apiHeader())
    .then(res => {
      return res?.header?.location ? getUser(res.header.location) : null
    })
    .catch(err => {
      if (err.stack && ((err.response && err.response.statusType !== 4) || !err.response)) {
        debug.error(err.stack)
      }
      throw err
    })
}

export const postSignOut = async () => {
  const ajax = (await import('~/lib/utils/ajax')).default
  return ajax
    .postJSON(apiLegacy.signOutUrl(), null, apiLegacy.apiHeader())
    .then(() => {
      removeLocalUser()
      return null
    })
    .catch(err => {
      if (err.stack && ((err.response && err.response.statusType !== 4) || !err.response)) {
        debug.error(err.stack)
      }
      throw err
    })
}

export const postSignUp = async data => {
  const ajax = (await import('~/lib/utils/ajax')).default
  return ajax
    .postJSON(apiLegacy.signup(), data, apiLegacy.apiHeader())
    .then(res => (res.header && res.header.location ? getUser(res.header.location) : null))
    .catch(err => {
      if (err.stack && ((err.response && err.response.statusType !== 4) || !err.response)) {
        debug.error(err.stack)
      }
      throw err
    })
}

export const postForgotPassword = async data => {
  const ajax = (await import('~/lib/utils/ajax')).default
  return ajax.postJSON(apiLegacy.forgotPassword(), data, apiLegacy.apiHeader()).catch(err => {
    if (err.stack && ((err.response && err.response.statusType !== 4) || !err.response)) {
      debug.error(err.stack)
    }
    throw err
  })
}

export const postSocialSignin = async query => {
  const { providerName, authCode, authError } = query
  if (authError) {
    // auth context will catch this
    throw new Error(authError)
  }
  if (providerName && authCode) {
    const ajax = (await import('~/lib/utils/ajax')).default
    return ajax
      .postJSON(apiLegacy.postUserSocialAuth(), query, apiLegacy.apiHeader())
      .then(authRes => {
        // the user API endpoint URI is returned in the location header
        if (!authRes?.header?.location) {
          return null
        }
        // Finally! Get the user
        return getUser(authRes.header.location)
      })
      .catch(err => {
        let errorMessage = ''
        if (err?.response?.body?.errors) {
          const { errors } = err.response.body
          Object.values(errors).forEach(e =>
            (e as any).forEach(m => {
              errorMessage += ` ${m}`
            }),
          )
        } else {
          errorMessage =
            err?.message ||
            err?.response?.error?.message ||
            err?.response?.text ||
            `unable to authenticate with ${providerName}`
        }
        return errorMessage
      })
  }
  return null
}

export default {
  getUser,
  postSignIn,
  postSignOut,
  postSignUp,
  postForgotPassword,
  postSocialSignin,
  // old store tests:
  removeLocalUser,
  setLocalUser,
  getLocalUser,
  getValidLocalUser,
  getAuthAccessToken,
  storageKey,
}
