import _invert from 'lodash/invert';
import { NavTypes } from '~lib/config/nav-types';
import { capitalize } from '~lib/transforms/capitalize';

export const types = {
  Buy: 1,
  Rent: 2,
  Sold: 3,
  Leased: 4,
  Agents: 5,
  Reviews: 6,
  Questions: 7,
};

export const searchParamsModeTypes = {
  buy: 1,
  rent: 2,
  sold: 3,
};

const invertedSearchParamsModeTypes = _invert(searchParamsModeTypes);

export const searchTypes = {
  sale: 'buy',
  rent: 'rent',
  saleFaceted: 'for-sale',
  rentFaceted: 'for-rent',
  sold: 'sold-properties',
  leased: 'leased',
};

export const getModeFromSearchParamsType = (bffType: string) =>
  searchParamsModeTypes?.[bffType] ?? searchParamsModeTypes.buy;

export const getSearchParamsTypeFromMode = (mode: number) => {
  return invertedSearchParamsModeTypes?.[mode] || invertedSearchParamsModeTypes.buy;
};

export const getDescriptionForType = (type: number = 1) => {
  return _invert(types)[type];
};

export const getBuyersOrRenters = (type = types.Buy) =>
  type === types.Rent ? 'Renters' : 'Buyers';

export const getVendorsOrLandlords = (type = types.Buy) =>
  type === types.Rent ? 'Landlords' : 'Vendors';

export const getModeDescription = (type = types.Buy, isBuy = false, isRental = false) => {
  switch (type) {
    case types.Rent:
      return isRental ? 'rental' : 'rent';
    case types.Sold:
      return 'sold';
    case types.Leased:
      return 'leased';
    default:
      return isBuy ? 'buy' : 'sale';
  }
};

export const formatListingsMode = (type = types.Buy) => {
  const text = capitalize(getModeDescription(type));
  return type === types.Buy || type === types.Rent ? `For ${text}` : text;
};

export const getModeRoute = (type: number) => {
  const mode = getModeDescription(type);
  switch (type) {
    case types.Buy:
    case types.Rent:
      return `/for-${mode}`;
    case types.Sold:
    case types.Leased:
      return `/${mode}-properties`;
    default:
      return `/${mode}`;
  }
};

export const getModePageName = (type: number): NavTypes => {
  switch (type) {
    case types.Rent:
      return NavTypes.Rent;
    case types.Sold:
      return NavTypes.Sold;
    default:
      return NavTypes.Buy;
  }
};
