import { canUseDOM } from '~/lib/utils/can-use-dom'
import { isLocalStorageEnabled, isSessionStorageEnabled } from './browser-support'
import Cookies from './cookies'

export const localStore = {
  getItem(cacheKey: string) {
    if (!canUseDOM) {
      return null
    }
    const existingCookie = Cookies().getJSON(cacheKey)
    if (existingCookie) {
      return existingCookie
    }
    if (isLocalStorageEnabled()) {
      const existingStorage = localStorage.getItem(cacheKey)
      if (existingStorage) {
        return JSON.parse(existingStorage)
      }
    }
    return null
  },

  setItem(cacheKey: string, data: any) {
    if (!canUseDOM) {
      return
    }
    if (isLocalStorageEnabled()) {
      localStorage.setItem(cacheKey, JSON.stringify(data))
    } else {
      Cookies().set(cacheKey, data)
    }
  },

  removeItem(cacheKey: string) {
    if (!canUseDOM) {
      return
    }
    if (isLocalStorageEnabled()) {
      localStorage.removeItem(cacheKey)
    } else {
      Cookies().remove(cacheKey)
    }
  },
}

// Session Storage
export const sessionStore =
  canUseDOM && isSessionStorageEnabled()
    ? {
        getItem(cacheKey: string) {
          const existingStorage = sessionStorage.getItem(cacheKey)
          return existingStorage ? JSON.parse(existingStorage) : null
        },

        setItem(cacheKey: string, data: any) {
          sessionStorage.setItem(cacheKey, JSON.stringify(data))
        },

        removeItem(cacheKey: string) {
          sessionStorage.removeItem(cacheKey)
        },
      }
    : {
        getItem() {
          return null
        },
        setItem() {
          return null
        },
        removeItem() {
          return null
        },
      }

export default localStore
