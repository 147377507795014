export const isTouch = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

export const isIOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (!userAgent) {
    return null;
  }
  return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i);
};

export const browserSupport = () => {
  document.body.classList.add(!isIOS() ? 'no-touch' : 'touch');
};

export const isLocalStorageEnabled = (storage = null) => {
  try {
    const myStorage = storage || localStorage || null;
    return myStorage && 'setItem' in myStorage && 'removeItem' in myStorage;
  } catch (e) {
    return false;
  }
};

export const isSessionStorageEnabled = (storage = null) => {
  try {
    const myStorage = storage || sessionStorage || null;
    return myStorage && 'setItem' in myStorage && 'removeItem' in myStorage;
  } catch (e) {
    return false;
  }
};
