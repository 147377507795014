import Cookies from 'js-cookie'
import appConfig from '~/lib/config/app-config'

let cachedCookies = null

const parse = (cookies: string) =>
  cookies.split(';').reduce((acc, line) => {
    const [key, value] = line.split('=')
    acc[key.trim()] = value
    return acc
  }, {} as Record<string, string>)

const updateCache = () => {
  if (typeof document !== 'undefined') {
    cachedCookies = parse(document.cookie)
  }
}

const parseDocumentCookies = () => {
  if (typeof window === 'undefined') return {}
  if (!cachedCookies) updateCache()
  return cachedCookies
}

const cookie = (cookies = parseDocumentCookies()) => ({
  find(key: string) {
    return cookies[key] || null
  },
  getJSON(key: string) {
    return Cookies.getJSON(key) || null
  },
  set(name: string, value: string) {
    Cookies.set(name, value)
    updateCache()
  },
  remove(name: string) {
    Cookies.remove(name)
    updateCache()
  },
  getAccessToken() {
    const accessTokenCookie = this.find(appConfig.get().accessTokenCookieName)
    if (accessTokenCookie) {
      return { Authorization: `Bearer ${accessTokenCookie}` }
    }
    return null
  },
  getAuthCookie() {
    let authCookie
    if (typeof window !== 'undefined') {
      // Logged in via username/password
      authCookie = this.find(`Auth-${window.location.host.split('.')[0]}`)
    }
    // If this user logged in via Google/Facebook etc
    return authCookie || this.find(appConfig.get().thirdPartyCookieName)
  },
  update() {
    updateCache()
  },
})

export default cookie
