import { createContext, type ReactNode, useContext, useState, useEffect } from 'react'
import { availableFeatures, isFeatureEnabled, type Feature } from './utils/features'

export interface FeaturesContextState {
  features: any
  updateFeature: (feature: string, enabled: boolean) => void
  featureEnabled: (feature: object) => boolean
}

interface Props {
  children: ReactNode
}

const FeaturesContext = createContext<Partial<FeaturesContextState>>({})
FeaturesContext.displayName = 'Listing Search Features Context'

const useFeatures = () => useContext(FeaturesContext)

const FeaturesProvider = ({ children }: Props) => {
  const [features, setFeatures] = useState(availableFeatures)

  useEffect(() => {
    const allFeatures = { ...availableFeatures }
    Object.keys(allFeatures).forEach(feature => {
      allFeatures[feature].enabled = isFeatureEnabled(feature)
    })
    setFeatures(allFeatures)
  }, [setFeatures])

  const updateFeature = (feature: string, enabled: boolean) => {
    const updatedFeatures = { ...features }
    updatedFeatures[feature].enabled = enabled
    setFeatures(updatedFeatures)
  }

  const featureEnabled = (feature: Feature) => {
    return features[feature.key].enabled
  }

  const value: FeaturesContextState = {
    features,
    updateFeature,
    featureEnabled,
  }
  return <FeaturesContext.Provider value={value}>{children}</FeaturesContext.Provider>
}

export { FeaturesContext, FeaturesProvider, useFeatures }
