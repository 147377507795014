import { Alert, SearchParams, SuburbsSearch } from '~/graphql/generated/graphql'
import { contentTypes, eventTypes, trackEvent } from '~lib/utils/google-tag-manager'
import { compareSearchParamsLocations } from '~/lib/providers/utils/alerts'

export const trackSaveAlert = (
  searchParams: SearchParams,
  alert: Alert,
  alertName: string,
  pageElementPosition: string,
) => {
  const alertLocations = searchParams.locationSearchContext as SuburbsSearch
  const extraData = [`${searchParams?.searchMode}`]
  if (alert) {
    if (!alert.name && alertName?.length > 0) {
      extraData.push('added_title')
    } else if (alert.name !== alertName) {
      extraData.push('edited_title')
    }
    if (
      !compareSearchParamsLocations(
        alert.searchParams.locationSearchContext as SuburbsSearch,
        alertLocations,
        true,
      )
    ) {
      extraData.push('changed_locations')
    }
  } else if (alertName?.length > 0) {
    extraData.push('has_title')
  }

  trackEvent({
    contentType: alert ? contentTypes.editAlert : contentTypes.newAlert,
    pageElementPosition,
    event: eventTypes.formSubmit,
    extraData: extraData.join(','),
    locationId: alertLocations.searchLocations?.map(l => l.id).join('_'),
  })
}

export const trackDeleteAlert = (alert: Alert, pageElementPosition: string) => {
  const { searchParams } = alert
  const alertLocations = searchParams.locationSearchContext as SuburbsSearch
  const extraData = [`${searchParams?.searchMode}`]
  trackEvent({
    contentType: contentTypes.deleteAlert,
    pageElementPosition,
    event: eventTypes.selectContent,
    extraData: extraData.join(','),
    locationId: alertLocations.searchLocations?.map(l => l.id).join('_'),
  })
}

export default {
  trackSaveAlert,
  trackDeleteAlert,
}
