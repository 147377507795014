import { HttpLink, type HttpOptions } from '@apollo/client/link/http'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'
import { ApolloLink } from '@apollo/client'

// TODO https://github.com/getsentry/sentry-javascript/blob/develop/MIGRATION.md#deprecate-hub
// import { captureException, getCurrentHub } from '@sentry/remix'

export const createHttpLink = (connectionOptions: HttpOptions) => new HttpLink(connectionOptions)

export const createBatchLink = (connectionOptions: HttpOptions) =>
  new BatchHttpLink(connectionOptions)

export const createPersistedLink = () => createPersistedQueryLink({ sha256 })

export const createErrorLink = () => {
  return onError(({ graphQLErrors, networkError, response, operation }) => {
    console.log('Error', graphQLErrors, networkError, response, operation)
    // TODO https://github.com/getsentry/sentry-javascript/blob/develop/MIGRATION.md#deprecate-hub
  })
}

export const createClientLinks = (connectionOptions: HttpOptions) => {
  const errorLink = createErrorLink()
  const persistedQueriesLink = createPersistedLink()
  const batchLink = createBatchLink(connectionOptions)
  return ApolloLink.from([errorLink, persistedQueriesLink, batchLink])
}

export const createServerLinks = (connectionOptions: HttpOptions) => {
  const errorLink = createErrorLink()
  const httpLink = createHttpLink(connectionOptions)
  return ApolloLink.from([errorLink, httpLink])
}
