import {
  type SearchParams,
  type SuburbsSearch,
  SearchMode,
  AlertSearchModeInput,
  type OnlyAndMinFilter,
  PropertyType,
} from '~/graphql/generated/graphql'
import { getModeFromSearchParamsType } from '~/lib/config/listing-search-mode-types'
import { formatBedLabel, formatListLabel } from '~/lib/config/listings-faceted-groups'
import { formatPriceRange } from '~/lib/transforms/format-price'
import { getPropertyTypeDesc } from '~/lib/utils/listings-faceted-metadata-helper'
import {
  compareArrays,
  compareMinAndMaxFilter,
  compareOnlyAndMinFilter,
  mergeOnlyAndMinFilter,
} from './search-params'

export const unsavedAlertStorageKey = 'unsavedAlert'

export const getAlertDetailsText = (
  locations: any[],
  searchParams: SearchParams,
  delimiter = ', ',
) => {
  const {
    price,
    searchMode,
    propertyTypes,
    bedrooms: beds,
    bathrooms: baths,
    carSpaces: cars,
  } = searchParams || {}
  // We'll always have a searchMode and at least one location
  const location = locations?.[0] || {}
  const details = [
    `${location.name}${location.stateCode ? `, ${location.stateCode}` : ''}${
      locations?.length > 1 ? `+${locations?.length - 1}` : ''
    }`,
    `${searchMode === 'buy' ? 'For Sale' : 'For Rent'}`,
  ]

  if (price && (price.min || price.max)) {
    details.push(
      formatPriceRange(
        { maximum: price?.max, minimum: price?.min },
        getModeFromSearchParamsType(searchMode),
      ),
    )
  }

  if (propertyTypes.length > 0) {
    const propertyType = propertyTypes?.[0]
    const suffix = propertyTypes.length > 1 ? `+${propertyTypes.length - 1}` : ''
    details.push(
      `${getPropertyTypeDesc(
        propertyType,
        !propertyType,
        propertyType === PropertyType.Land,
        true,
      )}${suffix}`,
    )
  }

  const bedrooms = mergeOnlyAndMinFilter(beds)
  const bathrooms = mergeOnlyAndMinFilter(baths)
  const carSpaces = mergeOnlyAndMinFilter(cars)

  if (bedrooms.length > 0) {
    details.push(formatBedLabel(bedrooms, 'Bed'))
  }
  if (bathrooms.length > 0) {
    details.push(formatListLabel(bathrooms, 'Bath'))
  }
  if (carSpaces.length > 0) {
    details.push(formatListLabel(carSpaces, 'Cars'))
  }

  return details.join(delimiter)
}

export const compareSearchParamsLocations = (
  locations: SuburbsSearch,
  alertLocations: SuburbsSearch,
  isExactMatch = false,
): boolean => {
  if (!locations?.searchLocations && !alertLocations?.searchLocations) {
    return true
  }
  const locationIds = locations?.searchLocations?.map(l => l.id)
  const alertLocationIds = alertLocations?.searchLocations?.map(l => l.id)
  return compareArrays(locationIds, alertLocationIds, isExactMatch)
}

export const compareAlertSearchParams = (
  searchParams: SearchParams,
  alertSearchParams: SearchParams,
) => {
  const {
    context,
    propertyTypes,
    searchMode,
    bedrooms,
    bathrooms,
    carSpaces,
    locationSearchContext,
    price,
  } = searchParams || {}
  const {
    context: alertContext,
    propertyTypes: alertPropertyTypes,
    searchMode: alertSearchMode,
    bedrooms: alertBedrooms,
    bathrooms: alertBathrooms,
    carSpaces: alertCarSpaces,
    locationSearchContext: alertLocationSearchContext,
    price: alertPrice,
  } = alertSearchParams || {}

  return (
    context === alertContext &&
    searchMode === alertSearchMode &&
    compareSearchParamsLocations(
      locationSearchContext as SuburbsSearch,
      alertLocationSearchContext as SuburbsSearch,
    ) &&
    compareArrays(propertyTypes, alertPropertyTypes) &&
    compareOnlyAndMinFilter(bedrooms, alertBedrooms) &&
    compareOnlyAndMinFilter(bathrooms, alertBathrooms) &&
    compareOnlyAndMinFilter(carSpaces, alertCarSpaces) &&
    compareMinAndMaxFilter(price, alertPrice)
  )
}

export const mapSearchParamsToAlert = (name: string, searchParams: SearchParams, id?: number) => {
  const onlyAndMinToInt = (onlyAndMin: OnlyAndMinFilter) => {
    const { min, only } = onlyAndMin || {}
    return {
      min: min && min > 0 ? parseInt(`${min}`, 10) : null,
      only: only ? only.map(o => parseInt(`${o}`, 10)) : null,
    }
  }
  const {
    searchMode,
    bedrooms,
    bathrooms,
    carSpaces,
    locationSearchContext,
    price,
    propertyTypes,
  } = searchParams || {}

  const { max, min } = price || {}
  const locationIds = (locationSearchContext as SuburbsSearch)?.searchLocations?.map(l => l.id)

  const idProp = id ? { id } : {}

  return {
    ...idProp,
    name,
    filter: {
      beds: onlyAndMinToInt(bedrooms),
      baths: onlyAndMinToInt(bathrooms),
      carSpaces: onlyAndMinToInt(carSpaces),
      locationIds,
      price: { max: max > 0 ? max : null, min: min > 0 ? min : null },
      propertyTypes,
      searchMode:
        searchMode === SearchMode.Rent ? AlertSearchModeInput.Rent : AlertSearchModeInput.Buy,
    },
  }
}
