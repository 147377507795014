
import { createContext, ReactNode, useContext, useState, useMemo } from 'react'
import { SearchLocation } from '~/graphql/generated/graphql'

export interface LocationsContextState {
  locations: SearchLocation[]
}
export interface LocationsContextApi {
  setLocations: (locations: SearchLocation[]) => void
  addLocation: (location: SearchLocation) => void
}

interface Props {
  initialLocations?: SearchLocation[] // for non-location searches (e.g. map, draw, office)
  children: ReactNode
}

const LocationsStateContext = createContext<LocationsContextState | null>(null)
const LocationsApiContext = createContext<LocationsContextApi | null>(null)

// Custom hooks
export const useLocationsState = () => {
  const context = useContext(LocationsStateContext)
  if (context === null) {
    throw new Error('useLocationsState must be used within a LocationsProvider')
  }
  return context
}

export const useLocationsApi = () => {
  const context = useContext(LocationsApiContext)
  if (context === null) {
    throw new Error('useLocationsApi must be used within a LocationsProvider')
  }
  return context
}

const LocationsProvider = ({ initialLocations = [], children }: Props) => {
  const [locations, setLocations] = useState(initialLocations)

  const state: LocationsContextState = {
    locations,
  }

  const api: LocationsContextApi = useMemo(
    () => ({
      setLocations: (newLocations: SearchLocation[]) => {
        return setLocations(newLocations)
      },
      addLocation: (location: SearchLocation) => setLocations([...locations, location]),
    }),
    [locations],
  )

  // Separate providers for state and API to avoid unnecessary re-renders
  // https://levelup.gitconnected.com/react-context-the-definitive-guide-to-best-practices-fd095568be03
  return (
    <LocationsStateContext.Provider value={state}>
      <LocationsApiContext.Provider value={api}>{children}</LocationsApiContext.Provider>
    </LocationsStateContext.Provider>
  )
}

export { LocationsProvider }
