import _invert from 'lodash/invert'
import _pick from 'lodash/pick'
import _values from 'lodash/values'
import { PropertyType } from '~/graphql/generated/graphql'

export const types = {
  Unknown: 0,
  Acreage: 1,
  Rural: 2,
  Alpine: 3,
  Apartment: 4,
  Block_of_Units: 5,
  Flat: 6,
  House: 7,
  Retirement: 8,
  SemiDetached: 9,
  ServicedApartment: 10,
  Studio: 11,
  Terrace: 12,
  Townhouse: 13,
  Unit: 14,
  Land: 15,
  Villa: 16,
  Warehouse: 17,
  Other: 18,
}

const typesInverted = _invert(types)
const typeLabels = {
  ...typesInverted,
  2: 'Rural Property',
  5: 'Block of Units',
  9: 'Semi-detached',
  10: 'Serviced Apartment',
}

const typesUri = {
  'real-estate': 0,
  acreages: 1,
  'rural-properties': 2,
  alpines: 3,
  apartments: 4,
  'block-of-units': 5,
  flats: 6,
  houses: 7,
  retirement: 8,
  'semi-detached': 9,
  'serviced-apartments': 10,
  studios: 11,
  terraces: 12,
  townhouses: 13,
  units: 14,
  land: 15,
  villas: 16,
  warehouses: 17,
  other: 18,
}

export const filterTypesAPI = {
  house: 7,
  apartment: 4,
  unit: 14,
  townhouse: 13,
  villa: 16,
  land: 15,
  acreage: 1,
  rural: 2,
  blockOfUnits: 5,
}

export const typesAPI = {
  ...filterTypesAPI,
  alpine: 3,
  flat: 6,
  retirement: 8,
  semiDetached: 9,
  servicedApartment: 10,
  studio: 11,
  terrace: 12,
  warehouse: 17,
  other: 18,
}

export const typesAPIInverted = _invert(typesAPI)

const landTypes = {
  residentialLand: 19,
  commercialLand: 20,
}
export const landTypesInverted = _values(_invert(landTypes))

const ruralTypes = {
  cropping: 21,
  dairy: 22,
  farmlet: 23,
  horticulture: 24,
  lifestyle: 25,
  livestock: 26,
  mixedFarming: 27,
  viticulture: 28,
  ruralOther: 29,
}
export const ruralTypesInverted = _values(_invert(ruralTypes))

const getFromIds = (ids, list: any = types) => {
  const invertedTypes = _invert(list)
  return _values(_pick(invertedTypes, ids))
}

export function getStringFromIds(ids) {
  return getFromIds(ids).join(', ').replace(/_/g, ' ')
}

export function getPluralStringFromIds(ids) {
  return getFromIds(ids, typesUri).join(', ').replace(/-/g, ' ')
}

export function getUriFromIds(ids) {
  return getFromIds(ids, typesUri).join(',')
}

// e.g. ["studio", "blockOfUnits"] -> "studios,block-of-units"
export const getUrisFromAPITypes = (apiTypes: PropertyType[] = []): string => {
  const validTypes = []
  apiTypes.forEach(apiType => {
    const uri = getFromIds(typesAPI[apiType], typesUri)
    if (uri.length) {
      validTypes.push(uri)
    }
  })
  return validTypes.length > 0 ? validTypes.join(',') : null
}

export function getApiFromIds(ids) {
  return getFromIds(ids, typesAPI)
}

export function getIdsFromApi(id) {
  return _values(_pick(typesAPI, id))
}

export function getStringFromApi(id) {
  if (typesAPI[id]) {
    return typeLabels[typesAPI[id]]
  }
  if (landTypes[id]) {
    return typeLabels[types.Land]
  }
  if (ruralTypes[id]) {
    return typeLabels[types.Rural]
  }
  return 'Property'
}

export const getIdFromApiName = name => {
  if (typesAPI[name]) {
    return typesAPI[name]
  }
  if (landTypes[name]) {
    return types.Land
  }
  if (ruralTypes[name]) {
    return types.Rural
  }
  return types.Other
}

export function filterPropertyTypes() {
  return _pick(types, [
    'House',
    'Apartment',
    'Unit',
    'Townhouse',
    'Villa',
    'Land',
    'Acreage',
    'Rural',
    'Block_of_Units',
  ])
}

export function typesInMostUsedOrder() {
  return _pick(types, [
    'Unknown',
    'House',
    'Apartment',
    'Unit',
    'Townhouse',
    'Land',
    'Rural',
    'Acreage',
    'Alpine',
    'Block_of_Units',
    'Flat',
    'Retirement',
    'SemiDetached',
    'ServicedApartment',
    'Studio',
    'Terrace',
    'Villa',
    'Warehouse',
    'Other',
  ])
}

export function getFullDescription(type, isPlural) {
  switch (type) {
    case types.Rural:
      return !isPlural ? 'Rural Property' : 'Rural Properties'
    case types.Block_of_Units:
      return !isPlural ? 'Block of Units' : 'Blocks of Units'
    case types.Land:
      return !isPlural ? 'Block of Land' : 'Blocks of Land'
    case types.Studio:
      return !isPlural ? 'Studio Apartment' : 'Studio Apartments'
    default:
      return ''
  }
}
