export const fillNumericArray = (arrayToFill) => {
  if (!arrayToFill || arrayToFill.length < 1 || isNaN(parseFloat(arrayToFill[0])))
    return arrayToFill;

  const minValue = arrayToFill[0];
  const maxValue = arrayToFill[arrayToFill.length - 1];
  const filledArray = [];

  for (let filler = minValue; filler <= maxValue; filler += 1) {
    filledArray.push(filler);
  }

  return filledArray;
};

// Add item to an array, ensuring it's at the start of the array and that the array doesn't go over maxLength
export const safelyAddToArray = (array = [], item: any, maxLength: number = 100): any[] => {
  const workArray = [...array];

  // If item already exists, remove it so we can add it at the start
  const existingItemIndex = workArray.indexOf(item);
  if (existingItemIndex > -1) {
    // remove item from array
    workArray.splice(existingItemIndex, 1);
  }

  // Add the new item to the start of array
  workArray.unshift(item);

  // If our array is too big now, remove the last item
  if (workArray.length > maxLength) {
    workArray.pop();
  }

  return workArray;
};

export default { fillNumericArray, safelyAddToArray };
