import { isFeatureEnabled, availableFeatures } from '~/lib/providers/utils/features'
import { get } from '~/lib/config/app-config'
import cookies from '~/lib/utils/cookies'
import isProduction from '~/lib/utils/is-production'

export const inflector = (count, noun) => {
  return Number.parseInt(count, 10) === 1 ? `${noun}` : `${noun}s`
}

export const getAppVersion = async (config = get()) => {
  return "TODO"
}

// Compares the server build version with the client build version and if there's a mismatch does a simple reload
// Uses a cookie to determine if a reload has happened to prevent reload loops plus to assist in showing a success message
let serverVersion = getAppVersion()
let lastServerCheck = new Date()
export const checkAppVersion = async () => {
  if (!isProduction()) return
  const cookieName = 'appUpdated'
  const showSystemNotifications = isFeatureEnabled(availableFeatures.systemNotifications.key)
  try {
    // Check every minute
    if (new Date().getTime() - lastServerCheck.getTime() > 60000) {
      const serverCheck = await fetch('/api/version', { method: 'HEAD' })
      serverVersion = serverCheck.headers.get('x-homely-version')?.replace('homely-web@', '')
      lastServerCheck = new Date()
    }
    const clientVersion = getAppVersion()
    const updateApp =
      serverVersion && serverVersion !== clientVersion && cookies().find(cookieName) !== 'true'
    if (updateApp) {
      cookies().set(cookieName, true)
      if (showSystemNotifications) {
        // TODO replace toast
        console.log(`Updating Homely to the latest version (v${clientVersion} → v${serverVersion})`)
        // Delay reloading to give the user a chance to see the message
        window.setTimeout(() => window.location.reload(), 3000)
      } else {
        window.location.reload()
      }
    } else if (cookies().find(cookieName)) {
      if (showSystemNotifications) {
        // TODO replace toast
        console.log('Homely is up to date!')
      }
      cookies().remove(cookieName)
    }
  } catch (e) {
    // Not a big deal if this errors/times out
  }
}

export const randomNumber = (min = 0, max = 100) => {
  return parseInt(`${Math.random() * (max - min) + min}`, 10)
}

export const listObjectToDropdownArray = list => {
  const newArray = []
  Object.keys(list).forEach(item => {
    newArray.push({ value: `${list[item]}`, label: item.replace(/_/g, ' ') })
  })
  return newArray
}

export const round = (value, decimals) => {
  const pre = Math.round(Number(`${value}e${decimals}`))
  return Number(`${pre}e-${decimals}`)
}

export const getInitials = (name = '', initialCount = 2): string => {
  if (!name || name.length < 1) {
    return ''
  }
  return name
    .split(' ')
    .splice(0, initialCount)
    .map(t => t[0])
    .join('')
    .toUpperCase()
}
