import { createContext, type ReactNode, useEffect, useContext, useState, useCallback } from 'react'
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import storage from '~/lib/utils/storage'
import { createApolloClient } from '~/graphql/client'
import {
  type AlertsQuery,
  AlertsDocument,
  type SearchParams,
  type SearchLocation,
  AddAlertDocument,
  type AddAlertMutation,
  type AddAlertMutationVariables,
  type DeleteAlertMutation,
  type DeleteAlertMutationVariables,
  DeleteAlertDocument,
  type UpdateAlertMutation,
  type UpdateAlertMutationVariables,
  UpdateAlertDocument,
  type UpdateAlertInput,
  type AlertResult,
  type ClearAlertMutation,
  type ClearAlertMutationVariables,
  ClearAlertDocument,
  type Alert,
} from '~/graphql/generated/graphql'
import { getAuthAccessToken } from '~/lib/providers/utils/auth'
import { useUser } from './user-context'
import {
  compareAlertSearchParams,
  mapSearchParamsToAlert,
  unsavedAlertStorageKey,
} from './utils/alerts'
import { trackSaveAlert } from '~/modules/alerts/components/add-edit/components/form/form.tracking'

export interface AlertsContextState {
  alerts: Alert[]
  unreadCount: number
  loading: boolean
  error?: unknown
}
export interface AlertsContextApi {
  createAlert: (alertName: string, searchParams: SearchParams, pageElementPosition?: string) => void
  updateAlert: (alertId: number, alertName: string, searchParams: SearchParams) => void
  deleteAlert: (alertId: number) => void
  clearAlert: (alertId: number) => void
  getAlertBySearchParams: (
    searchParams: SearchParams,
    locations: SearchLocation[],
    mode: number,
  ) => Alert
}

interface Props {
  initialAlerts?: Alert[]
  children: ReactNode
}

const AlertsStateContext = createContext(null)
const AlertsApiContext = createContext(null)

// Custom hooks
export const useAlertsState = () => useContext(AlertsStateContext)
export const useAlertsApi = () => useContext(AlertsApiContext)

const AlertsProvider = ({ children, initialAlerts = [] }: Props) => {
  const [alerts, setAlerts] = useState(initialAlerts)
  const [unreadCount, setUnreadCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [accessToken, setAccessToken] = useState(null)
  const [apolloClient, setApolloClient] = useState(null as ApolloClient<NormalizedCacheObject>)
  const { user } = useUser()

  const setAlertsData = useCallback(
    (alertsData: AlertResult) => {
      if (alertsData) {
        const { results, unseenListingCount } = alertsData
        setAlerts(results)
        setUnreadCount(unseenListingCount)
      } else {
        setAlerts([])
        setUnreadCount(0)
      }
    },
    [setAlerts, setUnreadCount],
  )

  const loadAlerts = useCallback(async () => {
    // Need to set client here to ensure we get auth headers
    setError(null)
    if (user && apolloClient) {
      setLoading(true)
      apolloClient
        .query<AlertsQuery>({
          query: AlertsDocument,
          fetchPolicy: 'no-cache',
          context: { headers: { ...accessToken } },
        })
        .then(response => {
          setAlertsData(response.data.alerts as AlertResult)
          setLoading(false)
          setError(null)
        })
        .catch(e => {
          setLoading(false)
          setError(e.message)
        })
    } else {
      // User doesn't have access token or isn't logged in
      setLoading(false)
      setAlertsData(null)
    }
  }, [setLoading, setError, setAlertsData, user, accessToken, apolloClient])

  const createAlert = useCallback(
    (alertName: string, searchParams: SearchParams, pageElementPosition?: string) => {
      const newAlert = mapSearchParamsToAlert(alertName, searchParams, null)
      setError(null)
      if (!user && !accessToken) {
        // need to save the alert to storage with the consuming component prompting for auth
        storage.setItem(unsavedAlertStorageKey, {
          alertName,
          searchParams,
          pageElementPosition,
        })
      } else if (user && accessToken) {
        setLoading(true)
        apolloClient
          .mutate<AddAlertMutation, AddAlertMutationVariables>({
            mutation: AddAlertDocument,
            variables: { input: newAlert },
            context: { headers: { ...accessToken } },
          })
          .then(res => {
            if (res?.data?.addAlert?.alert) {
              if (pageElementPosition) {
                // Tracking as user wasn't logged in so we need to track the event here
                trackSaveAlert(
                  searchParams,
                  null, // tells tracking this is a new alert
                  alertName,
                  pageElementPosition,
                )
              }
              loadAlerts()
            } else {
              setError('Error creating Alert')
            }
            setLoading(false)
          })
          .catch(e => {
            setLoading(false)
            setError(e.message)
          })
      } else {
        setError('Unable to save Alert')
        setAlerts([])
      }
    },
    [user, accessToken, apolloClient, setLoading, setError, loadAlerts],
  )

  // if there is an alert in storage and we have a user, save it!
  useEffect(() => {
    if (user && accessToken) {
      const unsavedAlert = storage.getItem(unsavedAlertStorageKey)
      if (unsavedAlert) {
        createAlert(
          unsavedAlert.alertName,
          unsavedAlert.searchParams,
          unsavedAlert.pageElementPosition,
        )
        storage.removeItem(unsavedAlertStorageKey)
      }
    }
  }, [user, accessToken, createAlert])

  // When user changes (log in/log out), load alerts
  useEffect(() => {
    loadAlerts()
  }, [user, loadAlerts])

  useEffect(() => {
    const authAccessToken = getAuthAccessToken()
    setAccessToken(authAccessToken)

    // As we are setting unique headers for these requests we don't want to use the main Apollo Client
    const client: ApolloClient<NormalizedCacheObject> = authAccessToken
      ? createApolloClient()
      : null
    setApolloClient(client)
  }, [user, setAccessToken, setApolloClient])

  const state: AlertsContextState = {
    alerts,
    unreadCount,
    loading,
    error,
  }

  const api: AlertsContextApi = {
    createAlert,
    updateAlert: (alertId: number, alertName: string, searchParams: SearchParams) => {
      const updatedAlertFilter = mapSearchParamsToAlert(
        alertName,
        searchParams,
        alertId,
      ) as UpdateAlertInput
      setError(null)
      if (user && accessToken) {
        setLoading(true)
        apolloClient
          .mutate<UpdateAlertMutation, UpdateAlertMutationVariables>({
            mutation: UpdateAlertDocument,
            variables: { input: updatedAlertFilter },
            context: { headers: { ...accessToken } },
          })
          .then(res => {
            if (res?.data?.updateAlert?.alert) {
              loadAlerts()
            } else {
              setError('Error updating Alert')
            }
            setLoading(false)
          })
          .catch(e => {
            setLoading(false)
            setError(e.message)
          })
      } else {
        setError('Please login to update this Alert')
        setAlerts([])
      }
    },
    deleteAlert: (alertId: number) => {
      setError(null)
      setLoading(true)
      apolloClient
        .mutate<DeleteAlertMutation, DeleteAlertMutationVariables>({
          mutation: DeleteAlertDocument,
          variables: { alertId },
          context: { headers: { ...accessToken } },
        })
        .then(res => {
          if (res?.data?.deleteAlert?.alertId) {
            loadAlerts()
          } else {
            setError('Failed to delete Alert')
          }
          setLoading(false)
        })
        .catch(e => {
          setLoading(false)
          setError(e.message)
        })
    },
    clearAlert: (alertId: number) => {
      setError(null)
      setLoading(true)
      apolloClient
        .mutate<ClearAlertMutation, ClearAlertMutationVariables>({
          mutation: ClearAlertDocument,
          variables: { alertId },
          context: { headers: { ...accessToken } },
        })
        .then(res => {
          if (res?.data?.deleteAlertNewListing) {
            loadAlerts()
          } else {
            setError('Failed to clear properties from Alert')
            setLoading(false)
          }
        })
        .catch(e => {
          setLoading(false)
          setError(e.message)
        })
    },
    getAlertBySearchParams: (searchParams: SearchParams): Alert => {
      if (alerts?.length < 1 || !searchParams) {
        return null
      }
      const matchedAlert = alerts.find(alert =>
        compareAlertSearchParams(searchParams, alert.searchParams),
      )
      if (matchedAlert) {
        return matchedAlert
      }
      return null
    },
  }

  return (
    <AlertsStateContext.Provider value={state}>
      <AlertsApiContext.Provider value={api}>{children}</AlertsApiContext.Provider>
    </AlertsStateContext.Provider>
  )
}

export { AlertsProvider }
