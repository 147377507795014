import { canUseDOM } from '~/lib/utils/can-use-dom'
import { get } from '~/lib/config/app-config'
import cookies from '~/lib/utils/cookies'

export type Feature = {
  key: string
  label: string
  enabled: boolean
}

// List of available features
export const availableFeatures = {
  tracking: { key: 'tracking', label: 'Tracking API', enabled: false } as Feature,
  listingTrackingLog: {
    key: 'listingTrackingLog',
    label: 'Listing Tracking Log',
    enabled: false,
  } as Feature,
  agentTrackingLog: {
    key: 'agentTrackingLog',
    label: 'Agent Tracking Log',
    enabled: false,
  } as Feature,
  sentryTracking: { key: 'sentryTracking', label: 'Sentry Tracking', enabled: false } as Feature,
  gtmTrackingLog: { key: 'gtmTrackingLog', label: 'GTM Tracking Log', enabled: false } as Feature,
  myPlaces: { key: 'myPlaces', label: 'My Places', enabled: false } as Feature,
  advancedMapClustering: {
    key: 'advancedMapClustering',
    label: 'Advanced Clustering',
    enabled: false,
  } as Feature,
  systemNotifications: {
    key: 'systemNotifications',
    label: 'System Notifications',
    enabled: false,
  } as Feature,
  performanceHub: {
    key: 'performanceHub',
    label: 'Performance Hub',
    enabled: false,
  } as Feature,
  awardWinners: {
    key: 'awardWinners',
    label: 'Award Winners',
    enabled: false,
  } as Feature,
  thirdPartyScripts: {
    key: 'thirdPartyScripts',
    label: 'Third Party Scripts',
    enabled: false,
  } as Feature,
}

export const isFeatureEnabled = (featureName = '') => {
  // If feature is enabled in config or cookie, return true
  if (`${get()?.feature?.[featureName]}` === 'true' || cookies().find(featureName) !== null) {
    return true
  }

  if (canUseDOM) {
    // If feature is an experiment in Google Optimize.
    if (window.experiments) {
      const experiments = window.experiments.filter(experiment => {
        if (experiment.feature && experiment.feature !== featureName) {
          return false
        }
        if (
          experiment.targetUrl &&
          !window.location.href.toLowerCase().includes(experiment.targetUrl)
        ) {
          return false
        }
        return true
      })
      if (experiments.length > 0) {
        return true
      }
    }
  }

  // Not enabled!
  return false
}

export default availableFeatures
