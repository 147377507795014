import { config as devConfig } from './app-config-dev'
import { config as prodConfig } from './app-config-prod'

let appConfig
export const set = config => {
  appConfig = config
}

export const get = () => {
  // if (appConfig) {
  //   return appConfig
  // }

  // const env = 'qa'
  // const config = devConfig

  // get app version
  // const appVersion = require('../../../package.json').version || null
  // if (appVersion) {
  //   config.appVersion = appVersion
  // }

  // Update config
  // appConfig = config
  return devConfig
}
export default { get, set }
