import _filter from 'lodash/filter';
import _includes from 'lodash/includes';

export const OutdoorFeatures = {
  Balcony: 'balcony',
  Courtyard: 'courtyard',
  Deck: 'deck',
  FullyFenced: 'fullyFenced',
  EntertainingArea: 'outdoorEnt',
  OutsideSpa: 'outsideSpa',
  Pool: 'pool',
  RemoteGarage: 'remoteGarage',
  SecureParking: 'secureParking',
  Shed: 'shed',
  TennisCourt: 'tennisCourt',
  Workshop: 'workshop',
};

export const list = [
  { value: 'balcony', label: 'Balcony' },
  { value: 'courtyard', label: 'Courtyard' },
  { value: 'deck', label: 'Deck' },
  { value: 'fullyFenced', label: 'Fully Fenced' },
  {
    value: 'outdoorEnt',
    label: 'Entertaining Area',
  },
  { value: 'outsideSpa', label: 'Outside Spa' },
  { value: 'pool', label: 'Pool' },
  {
    value: 'remoteGarage',
    label: 'Remote Garage',
  },
  {
    value: 'secureParking',
    label: 'Secure Parking',
  },
  { value: 'shed', label: 'Shed' },
  { value: 'tennisCourt', label: 'Tennis Court' },
  { value: 'workshop', label: 'Workshop' },
];

export default list;

const canonical = [OutdoorFeatures.Pool];

export const OutdoorCanonical = _filter(list, (item: any) => _includes(canonical, item.value));

export const OutdoorNonCanonical = _filter(list, (item: any) => !_includes(canonical, item.value));
