import url from 'url'
import qs from 'qs'

import { get } from '~/lib/config/app-config'
import URIHelper from '~/lib/utils/uri-helper'

const getUriHelper: any = () => {
  const config = get()
  const apiUrl = url.parse(config.apiUrl)
  return new URIHelper(apiUrl.protocol, apiUrl.host)
}

export default {
  apiUrl() {
    return getUriHelper().formatUrl('/')
  },

  officesSearch(data) {
    return getUriHelper().formatQueryUrl('/offices', getUriHelper().packageJson(data))
  },

  agentsSearch(data) {
    return getUriHelper().formatQueryUrl('/agents', getUriHelper().packageJson(data))
  },

  followingAgents(userId) {
    return getUriHelper().formatUrl(`/agent/${userId}/following`)
  },

  agentsAndLocationsSearch(query) {
    return getUriHelper().formatSearchUrl(
      '/search/agentsandlocations',
      qs.stringify({ q: query }, { allowDots: true, encode: false }),
    )
  },

  officesAndLocationsSearch(query) {
    return getUriHelper().formatSearchUrl(
      '/search/officesandlocations',
      qs.stringify({ q: query }, { allowDots: true, encode: false }),
    )
  },

  locationSearch(query) {
    return getUriHelper().formatSearchUrl(
      '/search/locations',
      qs.stringify({ q: query }, { allowDots: true, encode: false }),
    )
  },

  getAgent(username) {
    return getUriHelper().formatUrl(`/agent/${username}`)
  },

  getAgents(userIds, skip = 0, take = 5) {
    const json = JSON.stringify({ filter: { userIds }, paging: { skip, take } })
    return getUriHelper().formatSearchUrl(
      '/agents',
      qs.stringify({ json }, { allowDots: true, encode: false }),
    )
  },

  getAgentReviews(agentId, skip, take) {
    return getUriHelper().formatSearchUrl(
      `/agent/${agentId}/reviews`,
      qs.stringify({ skip, take }, { allowDots: true, encode: false }),
    )
  },

  getAgentSingleReview(reviewId) {
    return getUriHelper().formatUrl(`/agent/review/${reviewId}`)
  },

  getAgentFollowers(agentId, skip, take) {
    return getUriHelper().formatSearchUrl(
      `/agent/${agentId}/followers`,
      qs.stringify({ skip, take }, { allowDots: true, encode: false }),
    )
  },

  getAgentFollowing(agentId, skip, take) {
    return getUriHelper().formatSearchUrl(
      `/agent/${agentId}/following`,
      qs.stringify({ skip, take }, { allowDots: true, encode: false }),
    )
  },

  getAgentListingsStats(agentId) {
    return getUriHelper().formatUrl(`/agent/${agentId}/listings/stats`)
  },

  getListing(listingId) {
    return getUriHelper().formatUrl(`/listing/${listingId}`)
  },

  getEnquiryDetails(listingId) {
    return getUriHelper().formatUrl(`/listing/enquiry/${listingId}`)
  },

  postEnquiry() {
    return getUriHelper().formatUrl('/listing/enquiry')
  },

  getNearbySchools(listingId, take = 20) {
    return getUriHelper().formatSearchUrl(
      `/listing/${listingId}/schools`,
      qs.stringify({ take }, { allowDots: true, encode: false }),
    )
  },

  postSearchByAgent() {
    return getUriHelper().formatUrl('/listings/agent')
  },

  postSearchByIds() {
    return getUriHelper().formatUrl('/listings/ids')
  },

  getSearchListByIds(filter = {}) {
    return getUriHelper().formatQueryUrl('/listings/ids/list', getUriHelper().packageJson(filter))
  },

  getListSearchByAgent(filter) {
    return getUriHelper().formatQueryUrl('/listings/agent/list', getUriHelper().packageJson(filter))
  },

  getMapSearchByAgent(filter) {
    return getUriHelper().formatQueryUrl('/listings/agent/map', getUriHelper().packageJson(filter))
  },

  postSearchListByBoundary() {
    return getUriHelper().formatUrl('/listings/boundary/list')
  },

  getSearchListByBoundary(filter) {
    return getUriHelper().formatQueryUrl(
      '/listings/boundary/list',
      getUriHelper().packageJson(filter),
    )
  },

  postSearchMapByBoundary() {
    return getUriHelper().formatUrl('/listings/boundary/map')
  },

  getSearchMapByBoundary(filter) {
    return getUriHelper().formatQueryUrl(
      '/listings/boundary/map',
      getUriHelper().packageJson(filter),
    )
  },

  postSearchListByLocation() {
    return getUriHelper().formatUrl('/listings/location/list')
  },

  getSearchListByLocation(filter) {
    return getUriHelper().formatQueryUrl(
      '/listings/location/list',
      getUriHelper().packageJson(filter),
    )
  },

  postSearchMapByLocation(filter = {}) {
    return getUriHelper().formatUrl('/listings/location/map', getUriHelper().packageJson(filter))
  },

  getSearchMapByLocation(filter) {
    return getUriHelper().formatQueryUrl(
      '/listings/location/map',
      getUriHelper().packageJson(filter),
    )
  },

  postListingContactDetailsByIds() {
    return getUriHelper().formatUrl('/listings/ids/branding')
  },

  getLocation(uniqueUri, includeSurrounding = false) {
    return getUriHelper().formatQueryUrl(
      `/location/${uniqueUri}`,
      includeSurrounding ? { includeSurrounding: true } : null,
    )
  },

  getLocationByLatLong(latitude, longitude, locationType) {
    return getUriHelper().formatUrl(`/location/${latitude}/${longitude}/${locationType}`)
  },

  getLatLongByLocation(locationId) {
    return getUriHelper().formatUrl(`/location/${locationId}/spatial`)
  },

  getBoundary(id) {
    return getUriHelper().formatUrl(`/location/${id}/spatial`)
  },

  getLocationsNearby(id, take = 5) {
    return getUriHelper().formatSearchUrl(
      `/location/${id}/nearby`,
      qs.stringify({ take }, { allowDots: true, encode: false }),
    )
  },

  getLocationReviewsById(id) {
    return getUriHelper().formatUrl(`/location/${id}/pros`)
  },

  getLocationReview(id) {
    return getUriHelper().formatUrl(`/reviews/location/${id}`)
  },

  getAgentActivity(filter) {
    return getUriHelper().formatQueryUrl('/agents/activities', getUriHelper().packageJson(filter))
  },

  postListingEnquiry(listingId) {
    return getUriHelper().formatUrl(`/listing/${listingId}/enquiries`)
  },

  postDeveloperListingEnquiry() {
    return getUriHelper().formatUrl('/listing/enquiries/developerlisting')
  },

  postSellerEnquiry() {
    return getUriHelper().formatUrl('/listing/enquiries/seller')
  },

  postAgentEnquiry() {
    return getUriHelper().formatUrl('/agents/enquiries')
  },

  postAgentReview(agentId) {
    return getUriHelper().formatUrl(`/agent/${agentId}/reviews`)
  },

  postImportAgentReviews() {
    return getUriHelper().formatUrl('/agent/reviews/imported')
  },

  agentReviewById(reviewId) {
    return getUriHelper().formatUrl(`/agent/review/${reviewId}`)
  },

  getCompanies(stateId = null) {
    if (!stateId || stateId <= 0) {
      return getUriHelper().formatUrl('/companies/list')
    }
    return getUriHelper().formatSearchUrl(
      '/companies/list',
      qs.stringify({ stateId }, { allowDots: true, encode: false }),
    )
  },

  getOffices(companyId) {
    return getUriHelper().formatUrl(`/company/${companyId}/offices/list`)
  },

  getOffice(officeId) {
    return getUriHelper().formatUrl(`/v1/offices/${officeId}`)
  },

  // href is already formatted as `/franchises/[id]`
  getCompanyByHref(companyHref) {
    return getUriHelper().formatUrl(`/v1${companyHref}`)
  },

  alertById(alertId) {
    return getUriHelper().formatUrl(`/alert/${alertId}`)
  },

  alertsByUsername(username) {
    return getUriHelper().formatUrl(`/alerts/${username}`)
  },

  listingTracking() {
    return getUriHelper().formatUrl('/tracking/listings')
  },

  agentTracking() {
    return getUriHelper().formatUrl('/tracking/agents')
  },

  getQAndATopicsByLocation(locationId, type = 1, skip = 0, take = 5) {
    return getUriHelper().formatSearchUrl(
      `/questions/location/${locationId}`,
      qs.stringify({ type, skip, take }, { allowDots: true, encode: false }),
    )
  },

  userReviewedAgents(userId) {
    return getUriHelper().formatUrl(`/member/${userId}/agentreviews/summary`)
  },

  userFollowing(userId) {
    return getUriHelper().formatUrl(`/member/${userId}/following/summary`)
  },

  clearAllAlerts(username) {
    return getUriHelper().formatUrl(`/alerts/${username}/listings`)
  },

  clearAlert(alertId) {
    return getUriHelper().formatUrl(`/alert/${alertId}/listings`)
  },

  facebookPageLinkUsername() {
    return getUriHelper().formatUrl('/members/facebook-pages')
  },

  facebookPageGetUsername(pageId) {
    return getUriHelper().formatUrl(`/members/facebook-page/${pageId}`)
  },

  getMember(memberId) {
    return getUriHelper().formatUrl(`/member/${memberId}`)
  },

  getMemberSettings(memberId) {
    return getUriHelper().formatUrl(`/member/${memberId}/settings`)
  },

  getVendorReviews(agentUserId, status = 1, skip = 0, take = 20) {
    return getUriHelper().formatSearchUrl(
      '/reviews/vendor',
      qs.stringify({ agentUserId, status, skip, take }, { allowDots: true, encode: false }),
    )
  },

  getVendorReviewRequest(id) {
    return getUriHelper().formatUrl(`/reviews/vendor/${id}`)
  },

  postVendorReviewRequest() {
    return getUriHelper().formatUrl('/reviews/vendor/requests/')
  },
}
