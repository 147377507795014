import url from 'url'
import qs from 'qs'
import appConfig from '~/lib/config/app-config'
import URIHelper from '../utils/uri-helper'

// Documentation: https://github.com/Homely/Documentation/wiki/Users-(Legacy)

let uriHelper

function getUriHelper() {
  if (!uriHelper) {
    const config = appConfig.get()
    const apiUrl = url.parse(config.apiLegacyUrl)
    uriHelper = new URIHelper(apiUrl.protocol, apiUrl.host)
  }
  return uriHelper
}

export default {
  apiHeader() {
    return { DomainName: 'www.homely.com.au' }
  },

  apiUrl() {
    const config = appConfig.get()
    const apiUrl = url.parse(config.apiLegacyUrl)
    return url.format({ protocol: apiUrl.protocol, host: apiUrl.host })
  },

  refreshTokenExchange() {
    return getUriHelper().formatUrl('/api/user/tokenexchange')
  },

  signin() {
    return getUriHelper().formatUrl('/api/user/signin')
  },

  signOutUrl() {
    return getUriHelper().formatUrl('/api/user/signout')
  },

  signup() {
    return getUriHelper().formatUrl('/api/users')
  },

  forgotPassword() {
    return getUriHelper().formatUrl('/api/user/forgotpassword')
  },

  refreshAuth() {
    return getUriHelper().formatUrl('/api/user/authcookies')
  },

  createAlert() {
    return getUriHelper().formatUrl('/api/user/alerts/listings')
  },

  getUserByUsername(username) {
    return getUriHelper().formatUrl(`/api/user/${username}`)
  },

  getUser(getUserUrl) {
    return getUriHelper().formatUrl(getUserUrl)
  },

  getLocationUserData(uniqueUri) {
    return getUriHelper().formatSearchUrl(
      '/locations/location/overviewjsonuserdata',
      qs.stringify({ locationUniqueUri: uniqueUri }, { allowDots: true, encode: false }),
    )
  },

  follow(agentUserId) {
    return getUriHelper().formatUrl(`/api/agent/${agentUserId}/followers`)
  },

  unfollow(agentUserId) {
    return getUriHelper().formatUrl(`/api/agent/${agentUserId}/follower`)
  },

  getTransactions(agentUserId, currentUserId, skip, take) {
    return getUriHelper().formatSearchUrl(
      `/api/agent/${agentUserId}/transactions`,
      qs.stringify({ currentUserId, skip, take }, { allowDots: true, encode: false }),
    )
  },

  getTransactionById(transactionId, currentUserId) {
    return getUriHelper().formatSearchUrl(
      `/api/agent/transaction/${transactionId}`,
      qs.stringify({ currentUserId }, { allowDots: true, encode: false }),
    )
  },

  putTransaction(transactionId, currentUserId) {
    return getUriHelper().formatSearchUrl(
      `/api/agent/transaction/${transactionId}`,
      qs.stringify({ currentUserId }, { allowDots: true, encode: false }),
    )
  },

  postTransaction(agentId, currentUserId) {
    return getUriHelper().formatSearchUrl(
      `/api/agent/${agentId}/transactions`,
      qs.stringify({ currentUserId }, { allowDots: true, encode: false }),
    )
  },

  deleteTransaction(transactionId, currentUserId) {
    return getUriHelper().formatSearchUrl(
      `/api/agent/transaction/${transactionId}`,
      qs.stringify({ currentUserId }, { allowDots: true, encode: false }),
    )
  },

  postUserSocialAuth() {
    return getUriHelper().formatUrl('/api/user/authenticatesocial')
  },

  postReviewHelpful() {
    return getUriHelper().formatUrl('/posts/review/helpful')
  },

  postReviewUndoHelpful() {
    return getUriHelper().formatUrl('/posts/review/undohelpfulorunhelpful')
  },

  postReviewComment() {
    return getUriHelper().formatUrl('/posts/review/savecomment')
  },

  postReviewReport(id) {
    return getUriHelper().formatSearchUrl(
      '/user/report',
      qs.stringify({ postId: id }, { allowDots: true, encode: false }),
    )
  },

  postLocationFollow(locationUri) {
    return getUriHelper().formatUrl(`/${locationUri}/follow`)
  },

  postLocationUnfollow(locationUri) {
    return getUriHelper().formatUrl(`/${locationUri}/unfollow`)
  },

  postQuestionFollow(postId) {
    return getUriHelper().formatUrl(`/posts/post/follow/${postId}`)
  },

  postQuestionUnfollow(postId) {
    return getUriHelper().formatUrl(`/posts/post/unfollow/${postId}`)
  },

  postQuestionAnswer() {
    return getUriHelper().formatUrl('/posts/questions/answer')
  },

  postQuestionComment() {
    return getUriHelper().formatUrl('/posts/questions/answercomment')
  },

  postQuestionVote(locationUri, postUri) {
    return getUriHelper().formatUrl(`/${locationUri}/questions/${postUri}/vote`)
  },

  postQuestion(locationUri) {
    return getUriHelper().formatUrl(`/${locationUri}/questions/ask`)
  },
}
