import url from 'url';

export default class URIHelper {
  protocol;
  host;
  constructor(protocol, host) {
    this.protocol = protocol;
    this.host = host;
  }

  packageJson(json) {
    return { json: JSON.stringify(json) };
  }

  formatUrl(pathname) {
    return url.format({
      protocol: this.protocol,
      host: this.host,
      pathname,
    });
  }

  formatSearchUrl(pathname, search) {
    return url.format({
      protocol: this.protocol,
      host: this.host,
      pathname,
      search,
    });
  }

  formatQueryUrl(pathname, query?: string) {
    return url.format({
      protocol: this.protocol,
      host: this.host,
      pathname,
      query,
    });
  }
}
