export type FeatureListType = {
  value: string;
  label: string;
  uri?: string;
};

export const listingFeatures = {
  // Indoor features
  AirConditioning: 'airConditioning',
  AlarmSystem: 'alarmSystem',
  Broadband: 'broadband',
  BuiltInRobes: 'builtInRobes',
  Dishwasher: 'dishwasher',
  DuctedCooling: 'ductedCooling',
  DuctedHeating: 'ductedHeating',
  EvaporativeCooling: 'evaporativeCooling',
  Floorboards: 'floorboards',
  Furnished: 'furnished',
  GasHeating: 'gasHeating',
  Gym: 'gym',
  InsideSpa: 'insideSpa',
  Intercom: 'intercom',
  Fireplace: 'openFirePlace',
  PayTV: 'payTV',
  PetFriendly: 'petFriendly',
  RumpusRoom: 'rumpusRoom',
  SplitAircon: 'splitSystemAirCon',
  SplitHeating: 'splitSystemHeating',
  Study: 'study',
  VacuumSystem: 'vacuumSystem',
  // Outdoor features
  Balcony: 'balcony',
  Courtyard: 'courtyard',
  Deck: 'deck',
  FullyFenced: 'fullyFenced',
  EntertainingArea: 'outdoorEnt',
  OutsideSpa: 'outsideSpa',
  Pool: 'pool',
  RemoteGarage: 'remoteGarage',
  SecureParking: 'secureParking',
  Shed: 'shed',
  TennisCourt: 'tennisCourt',
  Workshop: 'workshop',
};

export const listingFeaturesCanonicalIndoor: FeatureListType[] = [
  { value: 'furnished', label: 'Furnished' },
  { value: 'gym', label: 'Gym' },
  {
    value: 'openFirePlace',
    label: 'Open Fireplace',
    uri: 'fireplace',
  },
  {
    value: 'petFriendly',
    label: 'Pet Friendly',
    uri: 'pet-friendly',
  },
];

export const listingFeaturesCanonicalOutdoor: FeatureListType[] = [
  { value: 'pool', label: 'Pool' },
];

export const listingFeaturesNonCanonicalIndoor: FeatureListType[] = [
  {
    value: 'airConditioning',
    label: 'Air Conditioning',
  },
  { value: 'alarmSystem', label: 'Alarm System' },
  { value: 'broadband', label: 'Broadband' },
  {
    value: 'builtInRobes',
    label: 'Built In Robes',
  },
  { value: 'dishwasher', label: 'Dishwasher' },
  {
    value: 'ductedCooling',
    label: 'Ducted Cooling',
  },
  {
    value: 'ductedHeating',
    label: 'Ducted Heating',
  },
  {
    value: 'evaporativeCooling',
    label: 'Evaporative Cooling',
  },
  { value: 'floorboards', label: 'Floorboards' },
  { value: 'gasHeating', label: 'Gas Heating' },
  { value: 'insideSpa', label: 'Inside Spa' },
  { value: 'intercom', label: 'Intercom' },
  { value: 'payTV', label: 'Pay TV' },
  { value: 'rumpusRoom', label: 'Rumpus Room' },
  {
    value: 'splitSystemAirCon',
    label: 'Split Aircon',
  },
  {
    value: 'splitSystemHeating',
    label: 'Split Heating',
  },
  { value: 'study', label: 'Study' },
  {
    value: 'vacuumSystem',
    label: 'Vacuum System',
  },
];

export const listingFeaturesNonCanonicalOutdoor: FeatureListType[] = [
  { value: 'balcony', label: 'Balcony' },
  { value: 'courtyard', label: 'Courtyard' },
  { value: 'deck', label: 'Deck' },
  { value: 'fullyFenced', label: 'Fully Fenced' },
  {
    value: 'outdoorEnt',
    label: 'Entertaining Area',
  },
  { value: 'outsideSpa', label: 'Outside Spa' },
  {
    value: 'remoteGarage',
    label: 'Remote Garage',
  },
  {
    value: 'secureParking',
    label: 'Secure Parking',
  },
  { value: 'shed', label: 'Shed' },
  { value: 'tennisCourt', label: 'Tennis Court' },
  { value: 'workshop', label: 'Workshop' },
];

export const listingFeaturesEco: FeatureListType[] = [
  { value: 'greyWaterSystem', label: 'Grey Water System' },
  { value: 'solarHotWater', label: 'Solar Hot Water' },
  { value: 'solarPanels', label: 'Solar Panels' },
  { value: 'waterTank', label: 'Water Tank' },
];

export const listingFeaturesList = []
  .concat(listingFeaturesCanonicalIndoor)
  .concat(listingFeaturesCanonicalOutdoor)
  .concat(listingFeaturesNonCanonicalIndoor)
  .concat(listingFeaturesNonCanonicalOutdoor)
  .concat(listingFeaturesEco);

export const listingFeaturesListCanonical = []
  .concat(listingFeaturesCanonicalIndoor)
  .concat(listingFeaturesCanonicalOutdoor);

export const listingFeaturesListNonCanonical = []
  .concat(listingFeaturesNonCanonicalIndoor)
  .concat(listingFeaturesNonCanonicalOutdoor)
  .concat(listingFeaturesEco)
  .sort((a, b) => a.label.localeCompare(b.label));

export const getFeaturesFromUri = (uri) => {
  const values = uri.toLowerCase().split(',');
  return listingFeaturesList
    .filter((item) => values.includes(item?.uri || item.value.toLowerCase()))
    .map((item) => item.value);
};

export default listingFeaturesList;
