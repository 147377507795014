// https://github.com/kentcdodds/bookshelf/blob/master/src/utils/hooks.js
import React, { useEffect, useLayoutEffect } from 'react'
import { canUseDOM } from '~/lib/utils/can-use-dom'

// React currently throws a warning when using useLayoutEffect on the server.
// To get around it, we can conditionally useEffect on the server (no-op) and
// useLayoutEffect in the browser. We need useLayoutEffect because we want
// `connect` to perform sync updates to a ref to save the latest props after
// a render is actually committed to the DOM.
const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect

function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false)
  useIsomorphicLayoutEffect((): any => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])
  return React.useCallback((...args) => (mounted.current ? dispatch(...args) : null), [dispatch])
}

// Example usage:
// const {data, error, status, run} = useAsync()
// React.useEffect(() => {
//   run(fetchPokemon(pokemonName))
// }, [pokemonName, run])
const defaultInitialState = { status: 'idle', data: null, error: null }

export function useAsync(initialState) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  })
  const [{ status, data, error }, setState] = React.useReducer(
    (s, a) => ({ ...s, ...a }),
    initialStateRef.current,
  )

  const safeSetState = useSafeDispatch(setState)

  const setData = React.useCallback(
    resData => safeSetState({ data: resData, status: 'resolved' }),
    [safeSetState],
  )
  const setError = React.useCallback(
    resError => safeSetState({ error: resError, status: 'rejected' }),
    [safeSetState],
  )
  const reset = React.useCallback(() => safeSetState(initialStateRef.current), [safeSetState])

  const run = React.useCallback(
    promise => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`,
        )
      }
      safeSetState({ status: 'pending' })
      return promise.then(
        runData => {
          setData(runData)
          return runData
        },
        runError => {
          setError(runError)
          return Promise.reject(runError)
        },
      )
    },
    [safeSetState, setData, setError],
  )

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  }
}

export default { useAsync }
