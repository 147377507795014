import { availableFeatures, isFeatureEnabled } from '~/lib/providers/utils/features'
import { ListingListingType, Maybe } from '~/graphql/generated/graphql'
import debug from '~/lib/utils/debug'

const track = data => {
  if (isFeatureEnabled(availableFeatures.gtmTrackingLog.key)) {
    debug.log('[GTM] Tracking: ', data)
  }
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

export const eventTypes = {
  selectContent: 'select_content',
  formSubmit: 'form_submit',
  login: 'login',
  openModal: 'open_modal',
  openForm: 'open_form',
  popupAppear: 'popup_appear',
  scrollTo: 'scroll_to',
  signUp: 'sign_up',
  swipe: 'swipe',
  error: 'errored',
  view: 'view',
}

export const contentTypes = {
  // for select_content events
  addToCalendarBtn: 'add_to_calendar_btn',
  addToCollection: 'add_to_collection',
  agentFinderLink: 'agent_finder_link',
  agentProfileLink: 'agent_profile_link',
  agentSearchLink: 'agent_search_link',
  alertFrequencyDaily: 'alert_frequency_daily',
  alertFrequencyInstant: 'alert_frequency_instant',
  alertFrequencyNone: 'alert_frequency_none',
  applyForRental: 'apply_for_rental',
  androidAppInstallDialog: 'android_app_install_dialog',
  androidAppSmartBanner: 'android_app_smart_banner',
  appStoreBtn: 'app_store_btn',
  applyWithSnug: 'apply_with_snug',
  backBtn: 'back_btn',
  bestStreetsLink: 'best_streets_link',
  blogArticleLink: 'blog_article_link',
  blogCategoryLink: 'blog_category_link',
  bookInspection: 'book_inspection',
  callAgentBtn: 'call_agent_btn',
  clearFiltersBtn: 'clear_filters_btn',
  collapseBtn: 'collapse_btn',
  copyAddress: 'copy_address',
  deleteAlert: 'delete_alert',
  deleteCollection: 'delete_collection',
  deleteCollectionMember: 'delete_collection_member',
  dislikeBtn: 'dislike_btn',
  drawOnMap: 'draw_on_map',
  editSaveSearchBtn: 'edit_save_search_btn',
  expandBtn: 'expand_btn',
  facetedNavFilter: 'faceted_nav_filter',
  followAgent: 'follow_agent',
  followLocation: 'follow_location',
  homelySociaLink: 'homely_social_link',
  homelyConnectPhoneLink: 'homely_connect_phone_link',
  likeBtn: 'like_btn',
  listView: 'list_view',
  listingCard: 'listing_card',
  listingSearchLink: 'listing_search_link',
  locationSearchLink: 'location_search_link',
  manageAlertsLink: 'manage_alerts_link',
  mapView: 'map_view',
  mapPin: 'map_pin',
  officeListingSearchLink: 'office_listing_search_link',
  playVideo: 'play_video',
  qASearchLink: 'q&a_search_link',
  reiwaLink: 'reiwa_link',
  removeFromCollection: 'remove_from_collection',
  schoolCardLink: 'school_card_link',
  searchThisAreaBtn: 'search_this_area_btn',
  showMorePinsBtn: 'show_more_pins_btn',
  smsAgentBtn: 'sms_agent_btn',
  snugLearnMoreLink: 'snug_learn_more_link',
  socialShareBtn: 'social_share_btn',
  sortList: 'sort_list',
  statementOfInformation: 'statement_of_information',
  staticMapBtn: 'static_map_btn',
  streetInSuburbSearchLink: 'street_in_suburb_search_link',
  streetDetailsLink: 'street_details_link',
  suburbDetailsLink: 'suburb_details_link',
  suburbInRegionSearchLink: 'suburb_in_region_search_link',
  suburbQADetailsLink: 'suburb_q&a_details_link',
  suburbQASearchLink: 'suburb_q&a_search_link',
  surroundingSuburbsOn: 'surrounding_suburbs_on',
  surroundingSuburbsOff: 'surrounding_suburbs_off',
  tooltipGotItBtn: 'tooltip_got_it_btn',
  unfollowAgent: 'unfollow_agent',
  unfollowLocation: 'unfollow_location',
  zoomInBtn: 'zoom_in_btn',
  zoomOutBtn: 'zoom_out_btn',
  performanceHubLink: 'performance_hub_link',
  requestInfoKitBtn: 'request_info_kit_btn',

  // for open_form / form_submit events
  agentReviewForm: 'agent_review_form',
  askQuestion: 'ask_question',
  authModal: 'auth_modal',
  contactAgent: 'contact_agent',
  createCollection: 'create_collection',
  editAlert: 'edit_alert',
  editCollection: 'edit_collection',
  editReview: 'edit_review',
  homeLoanCalculator: 'home_loan_calculator',
  instantListSignup: 'instant_list_signup',
  inviteToCollection: 'invite_to_collection',
  listingEnquiry: 'listing_enquiry',
  listingComment: 'listing_comment',
  locationSearch: 'location_search',
  newAlert: 'new_alert',
  qADetailsComment: 'q&a_details_comment',
  qADetailsCommentReply: 'q&a_details_comment_reply',
  reviewComment: 'review_comment',
  vsrAgentSendInvitationToClientForm: 'vsr_agent_send_invitation_to_client_form',
  writeReview: 'write_review',

  // for open_modal events
  addToCalendar: 'add_to_calendar',
  collections: 'collections',
  getConnectedCall: 'get_connected_call',
  listingGallery: 'listing_gallery',
  fullScreenGallery: 'full_screen_gallery',
  map: 'map',
  sharing: 'sharing',

  // for popup_appear events
  alertFrequencyTooltip: 'alert_frequency_tooltip',
  authPrompt: 'auth_prompt',
  drawOnMapTooltip: 'draw_on_map_tooltip',
  surroundingSuburbsTooltip: 'surrounding_suburbs_tooltip',

  // for scroll_to events
  listing2ndImgGallery: 'listing_2nd_img_gallery',
  surroundingSuburbsSeperator: 'surrounding_suburbs_seperator',

  // for swipe events
  agentCardCarousel: 'agent_card_carousel',
  listingsCarousel: 'listings_carousel',
  nearbyListingSearchCarousel: 'nearby_listing_search_carousel',
  qADetailsCarousel: 'q&a_details_carousel',
  schoolsCarousel: 'schools_carousel',
  similarListingsCarousel: 'similar_listings_carousel',
  suburbReviewsCarousel: 'suburb_reviews_carousel',
  streetDetailsCarousel: 'street_details_carousel',
  suburbInsightsCarousel: 'suburb_insights_carousel',
  developerListingsCarousel: 'developer_listings_carousel',

  // for errored events
  currentLocationNotFound: 'current_location_not_found',
  zero_results: '0_results',
}

export type GtmEvent = {
  event?: string
  pageElementPosition?: string
  contentType?: string
  agentId?: number
  officeId?: string | number
  listingId?: number
  listingTier?: string
  propertyType?: Maybe<string> | undefined
  listingType?: ListingListingType
  estimatePrice?: Maybe<number> | undefined
  numBeds?: number
  extraData?: any
  userId?: number
  locationId?: string | number
  pageType?: string
}

export const trackEvent = (options: GtmEvent) => {
  track({
    agentId: undefined,
    extraData: undefined,
    contentType: undefined,
    pageElementPosition: undefined,
    listingId: undefined,
    listingTier: undefined,
    officeId: undefined,
    propertyType: undefined,
    listingType: undefined,
    estimatePrice: undefined,
    numBeds: undefined,
    locationId: undefined,
    ...options,
  })
}

export const trackPage = (pageType: string, options: GtmEvent) => {
  track({
    event: 'page_view',
    pageType,
    listingId: undefined,
    listingTier: undefined,
    officeId: undefined,
    propertyType: undefined,
    listingType: undefined,
    estimatePrice: undefined,
    numBeds: undefined,
    locationId: undefined,
    ...options,
  })
}

export const trackUser = (userId = 0) => {
  track({
    userId,
  })
}
